import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule, Mutation } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";
import { EmailSurveyQue } from "@/core/types/EmailTypes";

@Module
export default class EmailModule extends VuexModule {
  emailSurveyQue = {} as EmailSurveyQue;
  emailQueList: Array<EmailSurveyQue> = [];
  emailJobQueList: Array<EmailSurveyQue> = [];

  get getQuedEmail(): EmailSurveyQue {
    return this.emailSurveyQue;
  }

  get getAllQuedEmails(): EmailSurveyQue[] {
    return this.emailQueList;
  }

  get getAllJobQuedEmails(): EmailSurveyQue[] {
    return this.emailJobQueList;
  }

  @Mutation
  [Mutations.SET_EMAIL_QUE](payload) {
    this.emailSurveyQue = payload.emailSurveyQue;
  }

  @Mutation
  [Mutations.SET_ALL_EMAIL_QUES](payload) {
    if (payload.typeOfSurvey === "behavioral") {
      this.emailQueList = payload.emailSurveyQues;
    } else if (payload.typeOfSurvey === "job") {
      this.emailJobQueList = payload.emailSurveyQues;
    }
  }

  @Mutation
  [Mutations.REMOVE_EMAIL_QUE](payload) {
    if (payload.typeOfSurveyEmail === "behavioral") {
      const objIndex = this.emailQueList.findIndex(
        (obj) => obj.id === payload.id
      );
      this.emailQueList.splice(objIndex, 1);
    } else {
      const objIndex = this.emailJobQueList.findIndex(
        (obj) => obj.id === payload.id
      );
      this.emailJobQueList.splice(objIndex, 1);
    }
  }

  @Mutation
  [Mutations.PURGE_EMAIL]() {
    this.emailSurveyQue = {} as EmailSurveyQue;
    this.emailQueList = [] as Array<EmailSurveyQue>;
    this.emailJobQueList = [] as Array<EmailSurveyQue>;
  }

  @Action
  [Actions.EMAIL_SURVEY](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("EmailMgmt/SendSurvey", payload)
        .then(({ data }) => {
          if (data.success) {
            //this.context.commit(Mutations.REMOVE_RECIPIENT_TO_EMAIL_LIST, data);
            resolve();
          } else {
            this.context.commit(Mutations.SET_ERROR, data);
            reject();
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          reject();
        });
    });
  }

  /*@Action
  [Actions.GET_EMAIL_QUE](payload) {
    ApiService.setHeader();
    ApiService.get("EmailMgmt/EmailQue/" + payload)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_EMAIL_QUE, data);
      })
      .catch(({ response }) => {
        console.log(response);
        this.context.commit(Mutations.SET_ERROR, response);
      });
  }*/

  @Action
  [Actions.GET_EMAIL_QUE](payload) {
    return new Promise<void>((resolve, reject) => {
      console.log("pay: " + payload);
      ApiService.post("EmailMgmt/EmailQue", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_EMAIL_QUE, data);
            resolve();
          } else {
            this.context.commit(Mutations.SET_ERROR, data);
            reject();
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_ALL_EMAIL_QUES](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("EmailMgmt/GetEmailQues", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_ALL_EMAIL_QUES, data);
            resolve();
          } else {
            this.context.commit(Mutations.SET_ERROR, data);
            reject();
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_EMAIL_QUE](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("EmailMgmt/DeleteEmailQue", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.REMOVE_EMAIL_QUE, data);
            resolve();
          } else {
            this.context.commit(Mutations.SET_ERROR, data);
            reject();
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.EMAIL_CONTENT_EXPERT](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("EmailMgmt/EmailContentExpert", payload)
        .then(({ data }) => {
          if (data.success) {
            //this.context.commit(Mutations.REMOVE_EMAIL_QUE, data);
            resolve();
          } else {
            this.context.commit(Mutations.SET_ERROR, data);
            reject();
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.EMAIL_SUPPORT](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("EmailMgmt/EmailSupport", payload)
        .then(({ data }) => {
          if (data.success) {
            //this.context.commit(Mutations.REMOVE_EMAIL_QUE, data);
            resolve();
          } else {
            this.context.commit(Mutations.SET_ERROR, data);
            reject();
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          reject();
        });
    });
  }
}
