import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import {
  LicensedDistributorDdlItem,
  LicensedDistributorAssociateDdlItem,
  ClientDdlItem,
  RespondentDdlItem,
  FolderDdlItem,
  ClientLocationDdlItem,
} from "../../core/types/FormDataTypes";
import { Client } from "../../core/types/ClientTypes";
import ApiService from "@/core/services/ApiService";

@Module
export default class FormDataModule extends VuexModule {
  licensedDistributorDDL: Array<LicensedDistributorDdlItem> = [];
  licensedDistributorAssociatesDDL: Array<LicensedDistributorAssociateDdlItem> =
    [];
  contentExpertDDL: Array<LicensedDistributorAssociateDdlItem> = [];
  licensedDistClientsDDL: Array<Client> = [];
  clientDDL: Array<ClientDdlItem> = [];
  clientFoldersDDL: Array<FolderDdlItem> = [];
  clientJOBFolderLocationDDL: Array<FolderDdlItem> = [];
  personalFoldersDDL: Array<FolderDdlItem> = [];
  respondentDDL: Array<RespondentDdlItem> = [];
  clientLocationDDL: Array<ClientLocationDdlItem> = [];

  get getLicensedDistributorDDL(): LicensedDistributorDdlItem[] {
    return this.licensedDistributorDDL;
  }

  get getLicensedDistributorAssociateDDL(): LicensedDistributorAssociateDdlItem[] {
    return this.licensedDistributorAssociatesDDL;
  }

  get getClientDDL(): ClientDdlItem[] {
    return this.clientDDL;
  }

  get getClientFoldersDDL(): FolderDdlItem[] {
    return this.clientFoldersDDL;
  }

  get getClientLocationsDDL(): ClientLocationDdlItem[] {
    return this.clientLocationDDL;
  }

  //--begin: used for match JOB survey to Behavioral Survey
  get getClientJOBFolderLocationDDL(): FolderDdlItem[] {
    return this.clientJOBFolderLocationDDL;
  }
  //--end: used for match JOB survey to Behavioral Survey

  get getLicensedDistClientsDDL(): Client[] {
    return this.licensedDistClientsDDL;
  }

  get getRespondentDDL(): RespondentDdlItem[] {
    return this.respondentDDL;
  }

  get getPersonalFoldersDDL(): FolderDdlItem[] {
    return this.personalFoldersDDL;
  }

  get getContentExpertDDL(): LicensedDistributorAssociateDdlItem[] {
    return this.contentExpertDDL;
  }

  @Mutation
  [Mutations.SET_LICENSED_DIST_DDL](payload) {
    this.licensedDistributorDDL = payload;
  }

  @Mutation
  [Mutations.SET_LICENSED_DIST_ASSOC_DDL](payload) {
    this.licensedDistributorAssociatesDDL = payload;
  }

  @Mutation
  [Mutations.SET_CONTENT_EXPERT_DDL](payload) {
    this.contentExpertDDL = payload;
  }

  @Mutation
  [Mutations.SET_CLIENT_DDL](payload) {
    this.clientDDL = payload;
  }

  @Mutation
  [Mutations.SET_CLIENT_BEHAVIORAL_FOLDERS_DDL](payload) {
    this.clientFoldersDDL = payload;
  }

  @Mutation
  [Mutations.SET_CLIENT_JOB_FOLDERS_DDL](payload) {
    this.clientFoldersDDL = payload;
  }

  @Mutation
  [Mutations.SET_CLIENT_LOCATIONS_DDL](payload) {
    this.clientLocationDDL = payload;
  }

  //--begin: used for match JOB survey to Behavioral Survey
  @Mutation
  [Mutations.SET_CLIENT_JOB_FOLDER_LOCATION_DDL](payload) {
    this.clientJOBFolderLocationDDL = payload;
  }
  //--end: used for match JOB survey to Behavioral Survey

  @Mutation
  [Mutations.SET_LICENSED_DIST_CLIENTS_DDL](payload) {
    this.licensedDistClientsDDL = payload;
  }

  @Mutation
  [Mutations.SET_RESPONDENT_DDL](payload) {
    this.respondentDDL = payload;
  }

  @Mutation
  [Mutations.SET_PERSONAL_FOLDERS_DDL](payload) {
    this.personalFoldersDDL = payload;
  }

  @Mutation
  [Mutations.PURGE_FORM_DATA]() {
    this.licensedDistributorDDL = [] as Array<LicensedDistributorDdlItem>;
    this.licensedDistributorAssociatesDDL =
      [] as Array<LicensedDistributorAssociateDdlItem>;
    this.licensedDistClientsDDL = [] as Array<Client>;
    this.clientDDL = [] as Array<ClientDdlItem>;
    this.clientFoldersDDL = [] as Array<FolderDdlItem>;
    this.personalFoldersDDL = [] as Array<FolderDdlItem>;
    this.respondentDDL = [] as Array<RespondentDdlItem>;
  }

  @Action
  [Actions.GET_LICENSED_DISTRIBUTOR_DDL]() {
    ApiService.setHeader();
    ApiService.get("FormData/LicensedDistributors")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_LICENSED_DIST_DDL, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.GET_LICENSED_DISTRIBUTOR_CLIENTS_DDL](payload) {
    ApiService.setHeader();
    ApiService.get("LicensedDistributorMgmt/Clients/" + payload)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_LICENSED_DIST_CLIENTS_DDL, data);
      })
      .catch(() => {
        this.context.commit(Mutations.SET_LICENSED_DIST_CLIENTS_DDL, [
          {
            name: "ERROR: Could not find a LD with DistAspNetUserId",
            numOfEmployees: 0,
            primaryContactName: "ERROR",
            primaryContactEmail: "ERROR",
            active: false,
          },
        ]);
      });
  }

  @Action
  [Actions.GET_LICENSED_DISTRIBUTOR_ASSOCIATES_DDL](payload) {
    ApiService.setHeader();
    ApiService.get("FormData/LiscDiscAssociates/" + payload)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_LICENSED_DIST_ASSOC_DDL, data);
      })
      .catch((response) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.GET_CLIENTS_DDL](payload) {
    ApiService.setHeader();
    ApiService.get("FormData/Clients/" + payload)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_CLIENT_DDL, data);
      })
      .catch((response) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.GET_CLIENT_BEHAVIORAL_FOLDERS_DDL](payload) {
    ApiService.setHeader();
    ApiService.get("FormData/ClientBehavioralFolders/" + payload)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_CLIENT_BEHAVIORAL_FOLDERS_DDL, data);
      })
      .catch((response) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.GET_CLIENT_JOB_FOLDERS_DDL](payload) {
    ApiService.setHeader();
    ApiService.get("FormData/ClientJobFolders/" + payload)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_CLIENT_JOB_FOLDERS_DDL, data);
      })
      .catch((response) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.GET_CLIENT_LOCATIONS_DDL](payload) {
    ApiService.setHeader();
    ApiService.get("FormData/ClientLocations/" + payload)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_CLIENT_LOCATIONS_DDL, data);
      })
      .catch((response) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  //--begin: used for match JOB survey to Behavioral Survey
  @Action
  [Actions.GET_CLIENT_JOB_FOLDER_LOCATION_DDL](payload) {
    ApiService.setHeader();
    ApiService.get("FormData/ClientJobFolders/" + payload)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_CLIENT_JOB_FOLDER_LOCATION_DDL, data);
      })
      .catch((response) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  //--end: used for match JOB survey to Behavioral Survey
  @Action
  [Actions.GET_PERSONAL_FOLDERS_DDL](payload) {
    ApiService.setHeader();
    ApiService.get("FormData/PersonalFolders/" + payload)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_PERSONAL_FOLDERS_DDL, data);
      })
      .catch((response) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.GET_CONTENT_EXPERT_DDL](payload) {
    ApiService.setHeader();
    ApiService.get("FormData/ContentExperts/" + payload)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_CONTENT_EXPERT_DDL, data);
      })
      .catch((response) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
}
