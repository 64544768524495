import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import {
  Client,
  ClientLocation,
  ClientUserLineItem,
  CurrentClientResponseDto,
} from "../../core/types/ClientTypes";
import { ApplicationUser } from "../../core/types/UserTypes";
import ApiService from "@/core/services/ApiService";

@Module
export default class UserModule extends VuexModule {
  clients: Array<Client> = [];
  clientUsers: Array<ApplicationUser> = [];
  clientUserList: Array<ClientUserLineItem> = [];
  location = {} as ClientLocation;
  currentClient = {} as CurrentClientResponseDto;

  /**
   * Get current client object
   * @returns Clients
   */
  get getClientList(): Client[] {
    return this.clients;
  }

  get getClientUserList(): ClientUserLineItem[] {
    return this.clientUserList;
  }

  get getUserFromClientUserList() {
    return (responsdentAspNetUserId: string) => {
      //console.log("user: " + responsdentAspNetUserId);
      return this.clientUserList.find(
        (user) => user.respondentAspNetUserId === responsdentAspNetUserId
      );
    };
  }

  get getClientInfo() {
    return (clientid: number) => {
      return this.clients.find((client) => client.clientId === clientid);
    };
  }

  get getCurrentClientInfo() {
    return this.currentClient;
  }

  get getPrimaryLocation() {
    return (clientid: number) => {
      for (
        let parentIndex = 0;
        parentIndex < this.clients.length;
        parentIndex++
      ) {
        if (this.clients[parentIndex].clientId === clientid) {
          return this.clients[parentIndex].locations.find(
            (loc) => loc.primaryLocation === true
          );
        }
      }
    };
  }

  get getClientLocations() {
    return (clientid: number) => {
      for (
        let parentIndex = 0;
        parentIndex < this.clients.length;
        parentIndex++
      ) {
        if (this.clients[parentIndex].clientId === clientid) {
          return this.clients[parentIndex].locations;
        }
      }
    };
  }

  get getClientLocationById() {
    return (clientid: number, clientlocationid: number) => {
      for (
        let parentIndex = 0;
        parentIndex < this.clients.length;
        parentIndex++
      ) {
        if (this.clients[parentIndex].clientId === clientid) {
          const found: boolean = this.clients[parentIndex].locations.some(
            (el) => el.clientLocationId === clientlocationid
          );
          if (found) {
            return this.clients[parentIndex].locations.find(
              (loc) => loc.clientLocationId === clientlocationid
            );
          } else {
            return this.location;
          }
        }
      }
    };
  }

  get getClientByName() {
    return (clientname: string) => {
      const found: boolean = this.clients.some((el) => el.name === clientname);
      if (found) {
        return this.clients.find((client) => client.name === clientname);
      } else {
        return this.currentClient;
      }
    };
  }

  get getClientAccountInfo() {
    return (accountid: string) => {
      return this.clientUsers.find((user) => user.id === accountid);
    };
  }

  @Mutation
  [Mutations.SET_CLIENTS](payload) {
    this.clients = payload;
  }

  @Mutation
  [Mutations.SET_CLIENT_USERS](payload) {
    this.clientUsers = payload.clientUsers;
  }

  @Mutation
  [Mutations.ADD_CLIENT_USER](payload) {
    this.clientUsers.unshift(payload);
  }

  @Mutation
  [Mutations.SET_CLIENT_USER_LIST](payload) {
    this.clientUserList = payload.clientUserListItems;
  }

  @Mutation
  [Mutations.ADD_CLIENT_USER_LISTITEM](payload) {
    this.clientUserList.unshift(payload);
  }

  @Mutation
  [Mutations.ADD_A_CLIENT](payload) {
    this.clients.unshift(payload);
  }

  @Mutation
  [Mutations.UPDATE_CLIENT_PROFILE](payload) {
    const objIndex = this.clients.findIndex(
      (obj) => obj.clientId === payload.clientId
    );
    this.clients[objIndex].name = payload.name;
    this.clients[objIndex].numOfEmployees = payload.numOfEmployees;
    this.clients[objIndex].primaryContactName = payload.primaryContactName;
    this.clients[objIndex].primaryContactEmail = payload.primaryContactEmail;
  }

  @Mutation
  [Mutations.REMOVE_CLIENT](payload) {
    const objIndex = this.clients.findIndex(
      (obj) => obj.clientId === payload.clientId
    );
    this.clients.splice(objIndex, 1);
  }

  @Mutation
  [Mutations.SET_CURRENT_CLIENT](payload) {
    this.currentClient = payload;
  }

  @Mutation
  [Mutations.ADD_A_CLIENT_LOCATION](payload) {
    const objIndex = this.clients.findIndex(
      (obj) => obj.clientId === payload.clientId
    );
    this.clients[objIndex].locations.push(payload);
  }

  @Mutation
  [Mutations.UPDATE_CLIENT_LOCATION_INFO](payload) {
    const objIndex = this.clients.findIndex(
      (obj) => obj.clientId === payload.clientId
    );
    const locIndex = this.clients[objIndex].locations.findIndex(
      (obj) => obj.clientLocationId === payload.clientLocationId
    );
    this.clients[objIndex].locations[locIndex].locationName =
      payload.locationName;
    this.clients[objIndex].locations[locIndex].phoneNumber =
      payload.phoneNumber;
    this.clients[objIndex].locations[locIndex].streetAddress =
      payload.streetAddress;
    this.clients[objIndex].locations[locIndex].streetAddress2 =
      payload.streetAddress2;
    this.clients[objIndex].locations[locIndex].city = payload.city;
    this.clients[objIndex].locations[locIndex].state = payload.state;
    this.clients[objIndex].locations[locIndex].postalCode = payload.postalCode;
  }

  @Mutation
  [Mutations.REMOVE_CLIENT_LOCATION](payload) {
    const objIndex = this.clients.findIndex(
      (obj) => obj.clientId === payload.clientId
    );
    const locIndex = this.clients[objIndex].locations.findIndex(
      (obj) => obj.clientLocationId === payload.clientLocationId
    );
    this.clients[objIndex].locations.splice(locIndex, 1);
  }

  @Mutation
  [Mutations.SET_PRIMARY_LOCATION](payload) {
    const objIndex = this.clients.findIndex(
      (obj) => obj.clientId === payload.clientId
    );
    for (let i = 0; i < this.clients[objIndex].locations.length; i++) {
      if (
        this.clients[objIndex].locations[i].clientLocationId ===
        payload.clientLocationId
      ) {
        this.clients[objIndex].locations[i].primaryLocation = true;
      } else {
        this.clients[objIndex].locations[i].primaryLocation = false;
      }
    }
  }

  @Mutation
  [Mutations.PURGE_CLIENTS]() {
    this.clients = [] as Array<Client>;
    this.location = {} as ClientLocation;
    this.clientUsers = [] as Array<ApplicationUser>;
    this.clientUserList = [] as Array<ClientUserLineItem>;
    this.currentClient = {} as CurrentClientResponseDto;
  }

  @Action
  [Actions.GET_CLIENTS]() {
    ApiService.setHeader();
    ApiService.get("ClientMgmt/Clients")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_CLIENTS, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.GET_CLIENT_USERS](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("ClientMgmt/ClientUsers", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_CLIENT_USERS, data);
            this.context.commit(Mutations.SET_CLIENT_USER_LIST, data);
            resolve();
          } else {
            this.context.commit(Mutations.SET_ERROR, data);
            reject();
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_RESPONDENT_CLIENT](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("ClientMgmt/CreateRespondentClient", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.ADD_CLIENT_USER_LISTITEM, data);
            resolve();
          } else {
            this.context.commit(Mutations.SET_ERROR, data);
            reject();
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_CLIENT](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("ClientMgmt/CreateClient", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.ADD_A_CLIENT, data);
            resolve();
          } else {
            this.context.commit(Mutations.SET_ERROR, data);
            reject();
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_CLIENT](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.put("ClientMgmt/UpdateClient", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.UPDATE_CLIENT_PROFILE, data);
            resolve();
          } else {
            this.context.commit(Mutations.SET_ERROR, data);
            reject();
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_CLIENT](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.delete("ClientMgmt/" + payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.REMOVE_CLIENT, data);
            resolve();
          } else {
            this.context.commit(Mutations.SET_ERROR, data);
            reject();
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_CURRENT_CLIENT](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get("ClientMgmt/CurrentClient/" + payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_CURRENT_CLIENT, data);
            this.context.commit(Mutations.ADD_A_CLIENT, data.client);
            resolve(data);
          } else {
            this.context.commit(Mutations.SET_ERROR, data);
            reject();
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_CLIENT_LOCATION](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("LocationMgmt/CreateLocation", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.ADD_A_CLIENT_LOCATION, data);
            resolve();
          } else {
            this.context.commit(Mutations.SET_ERROR, data);
            reject();
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_CLIENT_LOCATION](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.put("LocationMgmt/UpdateClientLocation", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.UPDATE_CLIENT_LOCATION_INFO, data);
            resolve();
          } else {
            this.context.commit(Mutations.SET_ERROR, data);
            reject();
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_CLIENT_LOCATION](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.delete("LocationMgmt/" + payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.REMOVE_CLIENT_LOCATION, data);
            resolve();
          } else {
            this.context.commit(Mutations.SET_ERROR, data);
            reject();
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.POST_PRIMARY_LOCATION](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("LocationMgmt/SetPrimaryLocation", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_PRIMARY_LOCATION, data);
            resolve();
          } else {
            this.context.commit(Mutations.SET_ERROR, data);
            reject();
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          reject();
        });
    });
  }
}
