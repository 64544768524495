import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { PrincipalTrait } from "../../core/types/TraitTypes";
import ApiService from "@/core/services/ApiService";

@Module
export default class PrincipalTraitModule extends VuexModule {
  principalTraitList: Array<PrincipalTrait> = [];

  get getPrincipalTraitList(): PrincipalTrait[] {
    return this.principalTraitList;
  }

  @Mutation
  [Mutations.SET_PRINCIPAL_TRAITS](payload) {
    this.principalTraitList = payload;
  }

  @Mutation
  [Mutations.PURGE_PRINCIPAL_TRAITS]() {
    this.principalTraitList = [] as Array<PrincipalTrait>;
  }

  @Action
  [Actions.GET_PRINCIPAL_TRAITS]() {
    ApiService.setHeader();
    ApiService.get("PrincipalTraitMgmt")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_PRINCIPAL_TRAITS, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
}
