import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";
import { FolderSurveyLineItemDto } from "@/core/types/SurveyTypes";
import { SurveyValidityLineItemDto } from "@/core/types/AnalysisTypes";

@Module
export default class AnalysisModule extends VuexModule {
  matchToJobProfileResults: Array<FolderSurveyLineItemDto> = [];
  surveyValidityResults: Array<SurveyValidityLineItemDto> = [];

  get matchJOBSurveyToBehavioralResults() {
    return this.matchToJobProfileResults;
  }

  get surveyAnalysisValidityResults() {
    return this.surveyValidityResults;
  }

  @Mutation
  [Mutations.SET_JOBSURVEY_TO_MATCH_BEHAVIORALSURVEY_RESULTS](payload) {
    this.matchToJobProfileResults = payload.behavioralProfiles;
  }

  @Mutation
  [Mutations.SET_SURVEY_VALIDITY_RESULTS](payload) {
    this.surveyValidityResults = payload.surveys;
  }

  @Action
  [Actions.POST_JOBSURVEY_TO_MATCH_BEHAVIORALSURVEY](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("JobMatchToBehavioralSurvey", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_JOBSURVEY_TO_MATCH_BEHAVIORALSURVEY_RESULTS,
              data
            );
            resolve(data);
          } else {
            this.context.commit(Mutations.SET_ERROR, data);
            reject();
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.POST_SURVEY_VALIDITY](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("SurveyValidity", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_SURVEY_VALIDITY_RESULTS, data);
            resolve(data);
          } else {
            this.context.commit(Mutations.SET_ERROR, data);
            reject();
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          reject();
        });
    });
  }
}
