import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";
import { InclineEnum } from "@/core/types/InclineEnumTypes";

@Module
export default class InclineEnumModule extends VuexModule {
  hireStatuses: Array<InclineEnum> = [];

  get getHiringStatuses(): InclineEnum[] {
    return this.hireStatuses;
  }

  @Mutation
  [Mutations.SET_INCLINE_HIRE_STATUS_ENUMS](payload) {
    this.hireStatuses = payload;
  }

  @Mutation
  [Mutations.PURGE_INCLINE_ENUMS]() {
    this.hireStatuses = [] as Array<InclineEnum>;
  }

  @Action
  [Actions.GET_INCLINE_HIRE_STATUS_ENUMS]() {
    ApiService.setHeader();
    ApiService.get("Enum/HireStatus")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_INCLINE_HIRE_STATUS_ENUMS, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
}
