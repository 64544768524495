import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { JobPhrase, JobPhraseLineItem } from "../../core/types/JOBTypes";
import { PrincipalTrait } from "../../core/types/TraitTypes";
import ApiService from "@/core/services/ApiService";

@Module
export default class JobPhrasesModule extends VuexModule {
  jobPhrases: Array<JobPhrase> = [];
  jobPhraseList: Array<JobPhraseLineItem> = [];
  principalTraitSummaryList: Array<PrincipalTrait> = [];
  jobPhrase = {} as JobPhrase;

  get getJobPhraseList(): JobPhraseLineItem[] {
    return this.jobPhraseList;
  }

  get getJOBAutonomyTraitCount(): number {
    return this.jobPhraseList.filter(
      (obj) => obj.principalTraitId === 1 && obj.active === true
    ).length;
  }

  get getJOBSocialTraitCount(): number {
    return this.jobPhraseList.filter(
      (obj) => obj.principalTraitId === 2 && obj.active === true
    ).length;
  }

  get getJOBPatienceTraitCount(): number {
    return this.jobPhraseList.filter(
      (obj) => obj.principalTraitId === 3 && obj.active === true
    ).length;
  }

  get getJOBCertaintyTraitCount(): number {
    return this.jobPhraseList.filter(
      (obj) => obj.principalTraitId === 4 && obj.active === true
    ).length;
  }

  get getJOBJudgementTraitCount(): number {
    return this.jobPhraseList.filter(
      (obj) => obj.principalTraitId === 6 && obj.active === true
    ).length;
  }

  get getJOBDistractorCount(): number {
    return this.jobPhraseList.filter(
      (obj) => obj.principalTraitId === 5 && obj.active === true
    ).length;
  }

  get getActiveJOBPhrases(): number {
    return this.jobPhraseList.filter((obj) => obj.active === true).length;
  }

  get getJobPhraseById() {
    return (phraseid: number) => {
      const found: boolean = this.jobPhrases.some(
        (el) => el.jobPhraseId === phraseid
      );
      if (found) {
        return this.jobPhrases.find(
          (phrase) => phrase.jobPhraseId === phraseid
        );
      } else {
        return this.jobPhrase;
      }
    };
  }

  @Mutation
  [Mutations.SET_JOB_PHRASES](payload) {
    this.jobPhrases = payload.jobPhrases;
    this.jobPhraseList = payload.jobPhraseLineItems;
  }

  @Mutation
  [Mutations.SET_JOB_PHRASELIST_ITEMS](payload) {
    this.jobPhraseList = payload;
  }

  @Mutation
  [Mutations.ADD_JOB_PHRASE](payload) {
    this.jobPhraseList.push(payload.jobPhraseLineItemDto);
    this.jobPhrases.push(payload.jobPhraseDto);
  }

  @Mutation
  [Mutations.UPDATE_JOB_PHRASE](payload) {
    const objIndex = this.jobPhrases.findIndex(
      (obj) => obj.jobPhraseId === payload.jobPhraseDto.jobPhraseId
    );
    this.jobPhrases[objIndex].phrase = payload.jobPhraseDto.phrase;
    this.jobPhrases[objIndex].active = payload.jobPhraseDto.active;
    this.jobPhrases[objIndex].order = payload.jobPhraseDto.order;
    this.jobPhrases[objIndex].principalTrait =
      payload.jobPhraseDto.principalTrait;
  }

  @Mutation
  [Mutations.UPDATE_JOB_PHRASE_LIST](payload) {
    const objIndex = this.jobPhraseList.findIndex(
      (obj) => obj.jobPhraseId === payload.jobPhraseLineItemDto.jobPhraseId
    );
    this.jobPhraseList[objIndex].phrase = payload.jobPhraseLineItemDto.phrase;
    this.jobPhraseList[objIndex].active = payload.jobPhraseLineItemDto.active;
    this.jobPhraseList[objIndex].order = payload.jobPhraseLineItemDto.order;
    this.jobPhraseList[objIndex].trait = payload.jobPhraseLineItemDto.trait;
    this.jobPhraseList[objIndex].principalTraitId =
      payload.jobPhraseLineItemDto.principalTraitId;
    this.jobPhraseList.sort(function (a, b) {
      return a.order - b.order;
    });
  }

  @Mutation
  [Mutations.UPDATE_ACTIVE_STATUS_JOB_PHRASE](payload) {
    const phraseIndex = this.jobPhrases.findIndex(
      (obj) => obj.jobPhraseId === payload.jobPhraseId
    );
    this.jobPhrases[phraseIndex].active = !this.jobPhrases[phraseIndex].active;

    const objIndex = this.jobPhraseList.findIndex(
      (obj) => obj.jobPhraseId === payload.jobPhraseId
    );
    this.jobPhraseList[objIndex].active = !this.jobPhraseList[objIndex].active;
  }

  @Mutation
  [Mutations.PURGE_JOB_PHRASES]() {
    this.jobPhrases = [] as Array<JobPhrase>;
    this.jobPhraseList = [] as Array<JobPhraseLineItem>;
    this.principalTraitSummaryList = [] as Array<PrincipalTrait>;
    this.jobPhrase = {} as JobPhrase;
  }

  @Action
  [Actions.GET_JOB_PHRASES]() {
    ApiService.setHeader();
    ApiService.get("JobPhraseMgmt/Phrases")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_JOB_PHRASES, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.GET_JOB_PHRASELIST_ITEMS]() {
    ApiService.setHeader();
    ApiService.get("JobPhraseMgmt/PhraseListItems")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_JOB_PHRASELIST_ITEMS, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.CREATE_JOB_PHRASE](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("JobPhraseMgmt/CreatePhrase", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.ADD_JOB_PHRASE, data);
            resolve();
          } else {
            this.context.commit(Mutations.SET_ERROR, data);
            reject();
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.PUT_JOB_PHRASE](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.put("JobPhraseMgmt/UpdatePhrase", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.UPDATE_JOB_PHRASE, data);
            this.context.commit(Mutations.UPDATE_JOB_PHRASE_LIST, data);
            resolve();
          } else {
            this.context.commit(Mutations.SET_ERROR, data);
            reject();
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.DEACTIVATE_REACTIVATE_JOB_PHRASE](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.delete("JobPhraseMgmt/" + payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.UPDATE_ACTIVE_STATUS_JOB_PHRASE,
              data
            );
            resolve();
          } else {
            this.context.commit(Mutations.SET_ERROR, data);
            reject();
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          reject();
        });
    });
  }
}
