enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  FORGOT_PASSWORD_RESET = "forgotPasswordReset",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",

  // user actions
  GET_APPLICATION_USERS = "getApplicationUsers",
  GET_APPLICATION_USERS_BY_ROLE = "getApplicationUsersByRole",
  CREATE_USER = "createUser",
  UPDATE_ACCOUNTPROFILE = "updateAccountProfile",
  DELETE_USER = "deleteUser",
  DEACTIVATE_REACTIVATE_USER = "deactivateReactivateUser",
  RESET_PASSWORD = "resetPassword",
  GET_APPLICATION_USER_PROFILE = "getApplicationUserProfile",

  // client actions
  GET_CLIENTS = "getClients",
  CREATE_CLIENT = "createClient",
  UPDATE_CLIENT = "updateClient",
  DELETE_CLIENT = "deleteClient",
  GET_CURRENT_CLIENT = "getCurrentClient",
  CREATE_CLIENT_LOCATION = "createClientLocation",
  UPDATE_CLIENT_LOCATION = "updateClientLocation",
  DELETE_CLIENT_LOCATION = "deleteClientLocation",
  POST_PRIMARY_LOCATION = "postPrimaryLocation",
  GET_CLIENT_USERS = "getClientUsers",

  // respondent actions
  CREATE_RESPONDENT_CLIENT = "createRespondentClient",
  GET_RESPONDENT_LIST = "getRespondentList",
  GET_RESPONDENT_LIST_ITEM = "getRespondentListItem",
  POST_LOOKUP_RESPONDENTS = "postLookupRespondents",
  CREATE_NEW_RESPONDENT = "createNewRespondent",

  // licensed distributor actions
  GET_LICENSED_DISTRIBUTORS = "getLicensedDistributors",
  GET_LICENSED_DISTRIBUTOR_LISTITEM = "getLicensedDistributorListItem",
  GET_LICENSED_DISTRIBUTOR_CLIENTS = "getLicensedDistributorClients",
  GET_LICENSED_DISTRIBUTOR_ASSOCIATES = "getLicensedDistributorAssociates",
  DELETE_LICENSED_DISTRIBUTOR = "deleteLicensedDistributor",
  GET_LICENSED_DISTRIBUTOR_ID = "getLicensedDistributorId",

  // licensed distributor associate actions
  CREATE_LICENSED_DIST_ASSOCIATE = "createLDAssociate",
  DELETE_LICENSED_DIST_ASSOCIATE = "deleteLicensedDistAssociate",
  GET_LICENSED_DIST_ASSOCIATE_CLIENTS = "getLicensedDistAssociateClients",
  POST_LICENSED_DISTRIBUTOR_ASSOC_CLIENT = "postLicensedDistributorAssocClient",
  REMOVE_LICENSED_DISTRIBUTOR_ASSOC_CLIENT = "removeLicensedDistributorAssocClient",
  GET_LDASSOCIATES_LICENSED_DIST_ASPNETUSERID = "getLdAssociatesLicensedDistAspNetUserId",
  GET_LDASSOCIATES_LICENSED_DISTRIBUTOR_ID = "getLdAssociatesLicensedDistributorId",
  GET_CONTENT_EXPERT = "getContentExpert",

  // Behavioral Words actions
  GET_BEHAVIORAL_WORDS = "getBehavioralWords",
  GET_BEHAVIORAL_WORDLIST_ITEMS = "getBehavioralWordListItems",
  CREATE_BEHAVIORAL_WORD = "createBehavioralWord",
  PUT_BEHAVIORAL_WORD = "putBehavioralWord",
  DEACTIVATE_REACTIVATE_BEHAVIORAL_WORD = "deactivateReactivateBehavioralWord",

  // Principal Traits actions
  GET_PRINCIPAL_TRAITS = "getPrincipalTraits",

  // JOB Phrases actions
  GET_JOB_PHRASES = "getJobPhrases",
  GET_JOB_PHRASELIST_ITEMS = "getJobPhraseListItems",
  CREATE_JOB_PHRASE = "createJobPhrase",
  PUT_JOB_PHRASE = "putJobPhrase",
  DEACTIVATE_REACTIVATE_JOB_PHRASE = "deactivateReactivateJobPhrase",

  // Form Data actions
  GET_LICENSED_DISTRIBUTOR_DDL = "getLicensedDistributorDdl",
  GET_LICENSED_DISTRIBUTOR_CLIENTS_DDL = "getLicensedDistributorClientsDdl",
  GET_LICENSED_DISTRIBUTOR_ASSOCIATES_DDL = "getLicensedDistributorAssociatesDdl",
  GET_CLIENTS_DDL = "getClientsDdl",
  GET_RESPONDENT_DDL = "getRespondentDdl",
  GET_CLIENT_BEHAVIORAL_FOLDERS_DDL = "getClientBehavioralFoldersDdl",
  GET_CLIENT_JOB_FOLDERS_DDL = "getClientJobFoldersDdl",
  GET_CLIENT_LOCATIONS_DDL = "getClientLocationsDdl",
  //--begin: used for match JOB survey to Behavioral Survey
  GET_CLIENT_JOB_FOLDER_LOCATION_DDL = "getClientJobFolderLocationDdl",
  //--end: used for match JOB survey to Behavioral Survey
  GET_PERSONAL_FOLDERS_DDL = "getPersonalFoldersDdl",
  GET_CONTENT_EXPERT_DDL = "getContentExpertDdl",

  // Incline Enum actions
  GET_INCLINE_HIRE_STATUS_ENUMS = "getInclineHireStatusEnums",

  // Survey actions
  CREATE_BEHAVIORAL_SURVEY = "createBehavioralSurvey",
  CREATE_JOB_SURVEY = "createJOBSurvey",
  TRANSFER_SURVEY = "transferSurvey",

  // Lookup actions
  LOOKUP_EMAIL = "lookupEmail",

  // Folder actions
  GET_CLIENT_FOLDER_LIST = "getClientFolderList",
  GET_CLIENT_FOLDER_CONTENTS = "getClientFolderContents",
  GET_BEHAVIORAL_PERSONAL_FOLDER_CONTENTS = "getBehavioralPersonalFolderContents",
  GET_JOB_PERSONAL_FOLDER_CONTENTS = "getJobPersonalFolderContents",
  PUT_REGENERATE_SURVEY_PDF = "putRegenerateSurveyPdf",

  // Email actions
  EMAIL_SURVEY = "emailSurvey",
  GET_EMAIL_QUE = "getEmailQue",
  GET_ALL_EMAIL_QUES = "getAllEmailQues",
  DELETE_EMAIL_QUE = "deleteEmailQue",
  EMAIL_CONTENT_EXPERT = "emailContentExpert",
  EMAIL_SUPPORT = "emailSupport",

  // Excel actions
  EXPORT_BEHAVIORAL_SURVEYS_TO_EXCEL = "exportBehavioralSurveysToExcel",
  EXPORT_JOB_MATCH_TO_BEHAVIORAL_SURVEYS_TO_EXCEL = "exportJobMatchToBehavioralSurveysToExcel",
  EXPORT_SURVEY_VALIDITY_STUDY_TO_EXCEL = "exportSurveyValidityStudyToExcel",

  // Analysis actions
  POST_JOBSURVEY_TO_MATCH_BEHAVIORALSURVEY = "postJobSurveyToMatchBehavioralSurvey",
  POST_SURVEY_VALIDITY = "postSurveyValidity",
}

enum Mutations {
  // user mutations
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_AUTHENTICATION = "setAuthentication",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",

  // user mutations
  SET_USERS = "setUsers",
  ADD_A_USER = "addAUser",
  UPDATE_USER_PROFILE = "updateUserProfile",
  REMOVE_USER = "removeUser",
  SET_LICENSED_DIST_ASSOCIATE_USERS = "setLicensedDistAssociateUsers",
  SET_APPLICATION_USER_PROFILE = "setApplicationUserProfile",
  PURGE_APPLICATION_USERS = "purgeApplicationUsers",

  // client mutations
  SET_CLIENTS = "setClient",
  ADD_A_CLIENT = "addClient",
  UPDATE_CLIENT_PROFILE = "updateClientProfile",
  REMOVE_CLIENT = "removeClient",
  SET_CURRENT_CLIENT = "setCurrentClient",
  ADD_A_CLIENT_LOCATION = "addClientLocation",
  UPDATE_CLIENT_LOCATION_INFO = "updateClientLocationInfo",
  REMOVE_CLIENT_LOCATION = "removeClientLocation",
  SET_PRIMARY_LOCATION = "setPrimaryLocation",
  SET_CLIENT_USERS = "setClientUsers",
  ADD_CLIENT_USER = "addClientUser",
  SET_CLIENT_USER_LIST = "setClientUserList",
  ADD_CLIENT_USER_LISTITEM = "addClientUserListItem",
  PURGE_CLIENTS = "purgeClients",

  // respondent mutations
  SET_RESPONDENT_LIST = "setRespondentList",
  ADD_NEW_RESPONDENT_TO_LIST = "addNewRespondentToList",
  SET_NEW_EMAIL_RESPONDENT_LIST_ITEM = "setRespondentListItem",
  PURGE_RESPONDENTS = "purgeRespondents",

  // licensed distributor mutations
  SET_LICENSED_DISTRIBUTORS = "setLicensedDistributors",
  ADD_A_LICENSED_DISTRIBUTOR = "addLicensedDistributor",
  SET_LICENSED_DIST_CLIENTS = "setLicensedDistClients",
  REMOVE_LICENSED_DISTRIBUTOR = "removeLicensedDistributor",
  SET_LICENSED_DISTRIBUTOR_ID = "setLicensedDistributorId",
  PURGE_LICENSED_DISTRIBUTOR = "purgeLicensedDitributor",

  // licensed distributor associate mutations
  SET_LICENSED_DISTRIBUTOR_ASSOCIATES = "setLicensedDistributorAssociates",
  ADD_A_LICENSED_DISTRIBUTOR_ASSOCIATE = "addLicensedDistributorAssociate",
  REMOVE_LICENSED_DISTRIBUTOR_ASSOCIATE = "removeLicensedDistributorAssociate",
  SET_LDASSOCIATES_LICENSED_DIST_ASPNETUSERID = "setLdAssociatesLicensedDistAspNetUserId",
  SET_LDASSOCIATES_LICENSED_DISTRIBUTOR_ID = "setLdAssociatesLicensedDistributorId",
  SET_CONTENT_EXPERT = "setContentExpert",

  // behavioral words mutations
  SET_BEHAVIORAL_WORDS = "setBehavioralWords",
  ADD_BEHAVIORAL_WORD = "addBehavioralWord",
  SET_BEHAVIORAL_WORDLIST = "setBehavioralWordList",
  ADD_BEHAVIORAL_WORDLIST_LINEITEM = "addBehavioralWordlistItem",
  SET_BEHAVIORAL_TRAIT_SUMMARY = "setBehavioralTraitSummary",
  ADD_BEHAVIORAL_TRAIT_SUMMARY = "addBehavioralTraitSummary",
  UPDATE_ACTIVE_STATUS_BEHAVIORAL_WORD = "updateActiveStatusBehavioralWord",
  UPDATE_BEHAVIORAL_WORD = "updateBehavioralWord",
  UPDATE_BEHAVIORAL_WORDLIST = "updateBehavioralWordList",
  UPDATE_PRINCIPAL_TRAIT_SUMMARY_LIST = "updatePrincipalTraitSummaryList",
  SET_BEHAVIORAL_WORDLIST_ITEMS = "setBehavioralWordListItems",
  SET_BEHAVIORAL_ENVIRONMENT_WORDLIST_ITEMS = "setBehavioralEnvironmentWordListItems",
  PURGE_BEHAVIORAL_WORDS = "purgeBehavioralWords",

  // principal traits mutations
  SET_PRINCIPAL_TRAITS = "setPrincipalTraits",
  PURGE_PRINCIPAL_TRAITS = "purgePrincipalTraits",

  // JOB phrase mutations
  SET_JOB_PHRASES = "setJobPhrases",
  SET_JOB_PHRASELIST_ITEMS = "setJobPhraseListItems",
  ADD_JOB_PHRASE = "addJobPhrase",
  UPDATE_JOB_PHRASE = "updateJobPhrase",
  UPDATE_JOB_PHRASE_LIST = "updateJobPhraseList",
  UPDATE_ACTIVE_STATUS_JOB_PHRASE = "updateActiveStatusJobPhrase",
  PURGE_JOB_PHRASES = "purgeJobPhrases",

  // Form Data mutations
  SET_LICENSED_DIST_DDL = "setLicensedDistDDL",
  SET_LICENSED_DIST_CLIENTS_DDL = "setLicensedDistClientsDDL",
  SET_LICENSED_DIST_ASSOC_DDL = "setLicensedDistAssocDDL",
  SET_CLIENT_DDL = "setClientDDL",
  SET_RESPONDENT_DDL = "setRespondentDDL",
  SET_CLIENT_BEHAVIORAL_FOLDERS_DDL = "setClientBehavioralFoldersDdl",
  SET_CLIENT_JOB_FOLDERS_DDL = "setClientJobFoldersDdl",
  SET_CLIENT_LOCATIONS_DDL = "setClientLocationsDdl",
  //--begin: used for match JOB survey to Behavioral Survey
  SET_CLIENT_JOB_FOLDER_LOCATION_DDL = "setClientJobFolderLocationDdl",
  //--end: used for match JOB survey to Behavioral Survey
  SET_PERSONAL_FOLDERS_DDL = "setPersonalFoldersDDL",
  SET_CONTENT_EXPERT_DDL = "setContentExpertDdl",
  PURGE_FORM_DATA = "purgeFormData",

  // Incline Enum mutations
  SET_INCLINE_HIRE_STATUS_ENUMS = "setInclineHireStatusEnums",
  PURGE_INCLINE_ENUMS = "purgeInclineEnums",

  // Survey mutations

  // Lookup mutations
  SET_EMAIL_LOOKUP_RESPONSE = "setEmailLookupResponse",
  PURGE_LOOKUPS = "purgeLookups",

  // Folder mutations
  SET_CLIENT_FOLDER_LIST = "setClientFolderList",
  SET_FOLDER_CONTENTS = "setFolderContents",
  SET_CLIENT_BEHAVIORAL_FOLDER_ID = "setClientBehavioralFolderId",
  REMOVE_SURVEY_FROM_FOLDERCONTENTS = "removeSurveyFromFolderContents",
  PURGE_FOLDERS = "purgeFolders",
  PURGE_FOLDER_CONTENTS = "purgeFolderContents",
  UPDATE_REGENERATED_SURVEY_PDF = "updateRegeneratedSurveyPdf",

  // Email mutations
  SET_EMAIL_QUE = "setEmailQue",
  SET_ALL_EMAIL_QUES = "setAllEmailQues",
  REMOVE_EMAIL_QUE = "removeEmailQue",
  PURGE_EMAIL = "purgeEmailQues",

  // Excel mutations
  SET_BEHAVIORAL_SURVEYS_TO_EXCEL = "setBehavioralSurveysToExcel",
  PURGE_EXCEL_FILE = "purgeExcelFile",

  // Analysis mutations
  SET_JOBSURVEY_TO_MATCH_BEHAVIORALSURVEY_RESULTS = "setJobSurveyToMatchBehavioralSurveyResults",
  SET_SURVEY_VALIDITY_RESULTS = "setSurveyValidityResults",
}

export { Actions, Mutations };
