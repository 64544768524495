import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";

@Module
export default class SurveyModule extends VuexModule {
  @Action
  [Actions.CREATE_BEHAVIORAL_SURVEY](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("SurveyMgmt/Behavioral", payload)
        .then(({ data }) => {
          if (data.success) {
            resolve();
          } else {
            this.context.commit(Mutations.SET_ERROR, data);
            reject();
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_JOB_SURVEY](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("SurveyMgmt/Job", payload)
        .then(({ data }) => {
          if (data.success) {
            //this.context.commit(Mutations.ADD_A_CLIENT, data);
            resolve();
          } else {
            this.context.commit(Mutations.SET_ERROR, data);
            reject();
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.TRANSFER_SURVEY](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("SurveyMgmt/TransferSurvey", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.REMOVE_SURVEY_FROM_FOLDERCONTENTS,
              data
            );
            resolve();
          } else {
            this.context.commit(Mutations.SET_ERROR, data);
            reject();
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          reject();
        });
    });
  }
}
