import { Actions, Mutations } from '@/store/enums/StoreEnums'
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators'
import {
  BehavioralWord,
  BehavioralWordLineItem,
} from '../../core/types/BehavioralTypes'
import { PrincipalTrait } from '../../core/types/TraitTypes'
import ApiService from '@/core/services/ApiService'

@Module
export default class BehavioralWordsModule extends VuexModule {
  behavioralWords: Array<BehavioralWord> = []
  behavioralWordsTableList: Array<BehavioralWordLineItem> = []
  principalTraitSummaryList: Array<PrincipalTrait> = []
  behavioralWord = {} as BehavioralWord
  behavioralWordListItems: Array<BehavioralWordLineItem> = []
  behavioralWordEnvironmentListItems: Array<BehavioralWordLineItem> = []

  get getBehavioralWordsList(): BehavioralWordLineItem[] {
    return this.behavioralWordsTableList
  }

  get getWordListItems(): BehavioralWordLineItem[] {
    return this.behavioralWordListItems
  }

  get getEnvironmentWordListItems(): BehavioralWordLineItem[] {
    return this.behavioralWordEnvironmentListItems
  }

  get getAutonomyTraitCount(): number {
    return this.principalTraitSummaryList.filter(
      (obj) => obj.principalTraitId === 1,
    ).length
  }

  get getSocialTraitCount(): number {
    return this.principalTraitSummaryList.filter(
      (obj) => obj.principalTraitId === 2,
    ).length
  }

  get getPatienceTraitCount(): number {
    return this.principalTraitSummaryList.filter(
      (obj) => obj.principalTraitId === 3,
    ).length
  }

  get getCertaintyTraitCount(): number {
    return this.principalTraitSummaryList.filter(
      (obj) => obj.principalTraitId === 4,
    ).length
  }

  get getJudgementTraitCount(): number {
    return this.principalTraitSummaryList.filter(
      (obj) => obj.principalTraitId === 6,
    ).length
  }

  get getDistractorCount(): number {
    return this.principalTraitSummaryList.filter(
      (obj) => obj.principalTraitId === 5,
    ).length
  }

  get getActiveBehavioralWords(): number {
    return this.behavioralWordsTableList.filter((obj) => obj.active === true)
      .length
  }

  get getBehavioralWordById() {
    return (wordid: number) => {
      const found: boolean = this.behavioralWords.some(
        (el) => el.behavioralWordId === wordid,
      )
      if (found) {
        return this.behavioralWords.find(
          (word) => word.behavioralWordId === wordid,
        )
      } else {
        return this.behavioralWord
      }
    }
  }

  @Mutation
  [Mutations.SET_BEHAVIORAL_WORDS](payload) {
    this.behavioralWords = payload.behavioralWordSummary
  }

  @Mutation
  [Mutations.ADD_BEHAVIORAL_WORD](payload) {
    this.behavioralWords.push(payload)
  }

  @Mutation
  [Mutations.SET_BEHAVIORAL_WORDLIST](payload) {
    this.behavioralWordsTableList = payload.behavioralWordsList
  }

  @Mutation
  [Mutations.SET_BEHAVIORAL_WORDLIST_ITEMS](payload) {
    this.behavioralWordListItems = payload.wordList
  }

  @Mutation
  [Mutations.SET_BEHAVIORAL_ENVIRONMENT_WORDLIST_ITEMS](payload) {
    this.behavioralWordEnvironmentListItems = payload.wordList
  }

  @Mutation
  [Mutations.ADD_BEHAVIORAL_WORDLIST_LINEITEM](payload) {
    this.behavioralWordsTableList.push(payload)
  }

  @Mutation
  [Mutations.SET_BEHAVIORAL_TRAIT_SUMMARY](payload) {
    this.principalTraitSummaryList = []
    for (let i = 0; i < payload.behavioralWordSummary.length; i++) {
      for (
        let m = 0;
        m < payload.behavioralWordSummary[i].behavioralWordTraits.length;
        m++
      ) {
        if (payload.behavioralWordSummary[i].active) {
          this.principalTraitSummaryList.push(
            payload.behavioralWordSummary[i].behavioralWordTraits[m],
          )
        }
      }
    }
  }

  @Mutation
  [Mutations.ADD_BEHAVIORAL_TRAIT_SUMMARY](payload) {
    for (let m = 0; m < payload.behavioralWordTraits.length; m++) {
      this.principalTraitSummaryList.push(payload.behavioralWordTraits[m])
    }
  }

  @Mutation
  [Mutations.UPDATE_BEHAVIORAL_WORD](payload) {
    const objIndex = this.behavioralWords.findIndex(
      (obj) => obj.behavioralWordId === payload.behavioralWord.behavioralWordId,
    )
    this.behavioralWords[objIndex].word = payload.behavioralWord.word
    this.behavioralWords[objIndex].spanishWord =
      payload.behavioralWord.spanishWord
    this.behavioralWords[objIndex].active = payload.behavioralWord.active
    this.behavioralWords[objIndex].order = payload.behavioralWord.order
    this.behavioralWords[objIndex].behavioralWordTraits =
      payload.behavioralWord.behavioralWordTraits
  }

  @Mutation
  [Mutations.UPDATE_BEHAVIORAL_WORDLIST](payload) {
    const objIndex = this.behavioralWordsTableList.findIndex(
      (obj) => obj.behavioralWordId === payload.lineItem.behavioralWordId,
    )
    this.behavioralWordsTableList[objIndex].word = payload.lineItem.word
    this.behavioralWordsTableList[objIndex].spanishWord =
      payload.lineItem.spanishWord
    this.behavioralWordsTableList[objIndex].active = payload.lineItem.active
    this.behavioralWordsTableList[objIndex].order = payload.lineItem.order
    this.behavioralWordsTableList[objIndex].traits = payload.lineItem.traits
    this.behavioralWordsTableList.sort(function (a, b) {
      return a.order - b.order
    })
  }

  @Mutation
  [Mutations.UPDATE_PRINCIPAL_TRAIT_SUMMARY_LIST](payload) {
    this.principalTraitSummaryList = []
    for (let i = 0; i < payload.behavioralWordSummary.length; i++) {
      for (
        let m = 0;
        m < payload.behavioralWordSummary[i].behavioralWordTraits.length;
        m++
      ) {
        if (payload.behavioralWordSummary[i].active) {
          this.principalTraitSummaryList.push(
            payload.behavioralWordSummary[i].behavioralWordTraits[m],
          )
        }
      }
    }
  }

  @Mutation
  [Mutations.UPDATE_ACTIVE_STATUS_BEHAVIORAL_WORD](payload) {
    const wordIndex = this.behavioralWords.findIndex(
      (obj) => obj.behavioralWordId === payload.behavioralWordId,
    )
    this.behavioralWords[wordIndex].active = !this.behavioralWords[wordIndex]
      .active

    const objIndex = this.behavioralWordsTableList.findIndex(
      (obj) => obj.behavioralWordId === payload.behavioralWordId,
    )
    this.behavioralWordsTableList[objIndex].active = !this
      .behavioralWordsTableList[objIndex].active

    this.principalTraitSummaryList = []
    for (let i = 0; i < this.behavioralWords.length; i++) {
      for (
        let m = 0;
        m < this.behavioralWords[i].behavioralWordTraits.length;
        m++
      ) {
        if (this.behavioralWords[i].active) {
          this.principalTraitSummaryList.push(
            this.behavioralWords[i].behavioralWordTraits[m],
          )
        }
      }
    }
  }

  @Mutation
  [Mutations.PURGE_BEHAVIORAL_WORDS]() {
    this.behavioralWords = [] as Array<BehavioralWord>
    this.behavioralWordsTableList = [] as Array<BehavioralWordLineItem>
    this.principalTraitSummaryList = [] as Array<PrincipalTrait>
    this.behavioralWord = {} as BehavioralWord
    this.behavioralWordListItems = [] as Array<BehavioralWordLineItem>
    this.behavioralWordEnvironmentListItems = [] as Array<
      BehavioralWordLineItem
    >
  }

  @Action
  [Actions.GET_BEHAVIORAL_WORDS]() {
    ApiService.setHeader()
    ApiService.get('BehavioralWordMgmt/Words')
      .then(({ data }) => {
        this.context.commit(Mutations.SET_BEHAVIORAL_WORDS, data)
        this.context.commit(Mutations.SET_BEHAVIORAL_WORDLIST, data)
        this.context.commit(Mutations.SET_BEHAVIORAL_TRAIT_SUMMARY, data)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors)
      })
  }

  @Action
  [Actions.GET_BEHAVIORAL_WORDLIST_ITEMS](payload) {
    ApiService.setHeader()
    ApiService.get('BehavioralWordMgmt/WordListItems/' + payload)
      .then(({ data }) => {
        if (data.surveyType === 'naturalself') {
          this.context.commit(Mutations.SET_BEHAVIORAL_WORDLIST_ITEMS, data)
        } else {
          this.context.commit(
            Mutations.SET_BEHAVIORAL_ENVIRONMENT_WORDLIST_ITEMS,
            data,
          )
        }
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors)
      })
  }

  @Action
  [Actions.CREATE_BEHAVIORAL_WORD](payload) {
    ApiService.setHeader()
    return new Promise<void>((resolve, reject) => {
      ApiService.post('BehavioralWordMgmt/CreateWord', payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.ADD_BEHAVIORAL_WORDLIST_LINEITEM,
              data.lineItem,
            )
            this.context.commit(
              Mutations.ADD_BEHAVIORAL_WORD,
              data.behavioralWord,
            )
            this.context.commit(
              Mutations.ADD_BEHAVIORAL_TRAIT_SUMMARY,
              data.behavioralWord,
            )
            resolve()
          } else {
            this.context.commit(Mutations.SET_ERROR, data)
            reject()
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data)
          reject()
        })
    })
  }

  @Action
  [Actions.PUT_BEHAVIORAL_WORD](payload) {
    ApiService.setHeader()
    return new Promise<void>((resolve, reject) => {
      ApiService.put('BehavioralWordMgmt/UpdateWord', payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.UPDATE_BEHAVIORAL_WORD, data)
            this.context.commit(Mutations.UPDATE_BEHAVIORAL_WORDLIST, data)
            this.context.commit(
              Mutations.UPDATE_PRINCIPAL_TRAIT_SUMMARY_LIST,
              data,
            )
            resolve()
          } else {
            this.context.commit(Mutations.SET_ERROR, data)
            reject()
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data)
          reject()
        })
    })
  }

  @Action
  [Actions.DEACTIVATE_REACTIVATE_BEHAVIORAL_WORD](payload) {
    ApiService.setHeader()
    return new Promise<void>((resolve, reject) => {
      ApiService.delete('BehavioralWordMgmt/' + payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.UPDATE_ACTIVE_STATUS_BEHAVIORAL_WORD,
              data,
            )
            resolve()
          } else {
            this.context.commit(Mutations.SET_ERROR, data)
            reject()
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data)
          reject()
        })
    })
  }
}
