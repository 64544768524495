import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from '@/store'
import { Actions } from '@/store/enums/StoreEnums'

const routes: Array<RouteRecordRaw> = [
  /*{
    path: "/",
    component: () => import("@/views/SiteIndex.vue"),
  },*/
  {
    path: '/',
    redirect: '/dashboard',
    component: () => import('@/layout/Layout.vue'),
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('@/views/Dashboard.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/contact-support',
        name: 'contact-support',
        component: () => import('@/views/Contact-Support.vue'),
        meta: { requiresAuth: true },
      },
      // SuperAdmin Users
      {
        path: '/super-admin/applicationuserlisting',
        name: 'applicationuserlisting',
        component: () =>
          import('@/views/super-admin/users/ApplicationUserListing.vue'),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          if (store.getters.currentUserRole !== 'SuperAdmin')
            next({ name: 'dashboard' })
          else next()
        },
      },
      // User Account Profile
      {
        path: '/account/:accountid/:clientid',
        name: 'account',
        component: () => import('@/views/account/Account.vue'),
        meta: { requiresAuth: true },
        props: true,
        children: [
          {
            path: '/overview/:accountid/:clientid',
            name: 'account-overview',
            component: () => import('@/views/account/Overview.vue'),
            meta: { requiresAuth: true },
            props: true,
          },
          {
            path: '/settings/:accountid/:clientid',
            name: 'account-settings',
            component: () => import('@/views/account/Settings.vue'),
            meta: { requiresAuth: true },
            props: true,
          },
        ],
      },
      //begin: Setup
      {
        path: '/super-admin/behavorialwords',
        name: 'sa-behavioral-words',
        component: () =>
          import('@/views/super-admin/setup/Behavioral-Words.vue'),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          if (store.getters.currentUserRole !== 'SuperAdmin')
            next({ name: 'dashboard' })
          else next()
        },
      },
      {
        path: '/super-admin/jobphrases',
        name: 'sa-job-phrases',
        component: () => import('@/views/super-admin/setup/JOB-Phrases.vue'),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          if (store.getters.currentUserRole !== 'SuperAdmin')
            next({ name: 'dashboard' })
          else next()
        },
      },
      //end: Setup

      //begin: SuperAdmin Clients
      {
        path: '/super-admin/clients',
        name: 'clients',
        component: () => import('@/views/super-admin/clients/Index.vue'),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          if (store.getters.currentUserRole !== 'SuperAdmin')
            next({ name: 'dashboard' })
          else next()
        },
      },
      //end: SuperAdmin Clients

      //begin: SuperAdmin Survey Analysis
      {
        path: '/survey-analysis/survey-validity-report',
        name: 'sa-survey-validity',
        component: () =>
          import('@/views/survey-analysis/Survey-Validity-Report.vue'),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          if (store.getters.currentUserRole !== 'SuperAdmin')
            next({ name: 'dashboard' })
          else next()
        },
      },
      {
        path: '/super-admin/frequencyreport',
        name: 'sa-frequency-report',
        component: () => import('@/views/survey-analysis/Frequency-Report.vue'),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          if (store.getters.currentUserRole !== 'SuperAdmin')
            next({ name: 'dashboard' })
          else next()
        },
      },
      //end: SuperAdmin Survey Analysis

      //begin: Survey Analysis Match Job Survey to Behavioral Survey
      {
        path: '/survey-analysis/match-job-survey',
        name: 'match-job-survey',
        props: true,
        component: () =>
          import('@/views/survey-analysis/Match-JOB-Survey-to-Behavioral.vue'),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          if (
            store.getters.currentUserRole == 'SuperAdmin' ||
            store.getters.currentUserRole == 'LicensedDistributor' ||
            store.getters.currentUserRole == 'LDAssociate' ||
            store.getters.currentUserRole == 'PowerUser'
          )
            next()
          else next({ name: 'dashboard' })
        },
      },
      //end: Survey Analysis Match Job Survey to Behavioral Survey

      //begin: Clients
      {
        path: '/client/:clientid',
        name: 'client',
        component: () => import('@/views/client/Client.vue'),
        meta: { requiresAuth: true },
        props: true,
        children: [
          {
            path: '/overview/:clientid',
            name: 'client-overview',
            component: () => import('@/views/client/Overview.vue'),
            meta: { requiresAuth: true },
            props: true,
          },
          {
            path: 'locations/',
            name: 'client-locations',
            component: () => import('@/views/client/Locations.vue'),
            meta: { requiresAuth: true },
            props: true,
          },
          {
            path: 'editclient/',
            name: 'edit-client',
            component: () => import('@/views/client/EditClient.vue'),
            meta: { requiresAuth: true },
            props: true,
          },
          {
            path: 'clientusers/',
            name: 'client-users',
            component: () => import('@/views/client/ClientUsers.vue'),
            meta: { requiresAuth: true },
            props: true,
            beforeEnter: (to, from, next) => {
              if (
                store.getters.currentUserRole == 'SuperAdmin' ||
                store.getters.currentUserRole == 'LicensedDistributor' ||
                store.getters.currentUserRole == 'LDAssociate' ||
                store.getters.currentUserRole == 'PowerUser'
              )
                next()
              else next({ name: 'dashboard' })
            },
          },
        ],
      },
      {
        path: '/client/survey-folder',
        name: 'client-survey-folder',
        component: () => import('@/views/folder-manager/Survey-Folder.vue'),
        meta: { requiresAuth: true },
        props: true,
        beforeEnter: (to, from, next) => {
          if (
            store.getters.currentUserRole == 'SuperAdmin' ||
            store.getters.currentUserRole == 'LicensedDistributor' ||
            store.getters.currentUserRole == 'LDAssociate' ||
            store.getters.currentUserRole == 'PowerUser' ||
            store.getters.currentUserRole == 'ClientUser'
          )
            next()
          else next({ name: 'dashboard' })
        },
      },
      {
        path: '/client/all-behavioral-surveys',
        name: 'client-all-behavioral-surveys',
        component: () =>
          import('@/views/folder-manager/All-Behavioral-Surveys.vue'),
        meta: { requiresAuth: true },
        props: true,
        beforeEnter: (to, from, next) => {
          if (
            store.getters.currentUserRole == 'SuperAdmin' ||
            store.getters.currentUserRole == 'LicensedDistributor' ||
            store.getters.currentUserRole == 'LDAssociate' ||
            store.getters.currentUserRole == 'PowerUser' ||
            store.getters.currentUserRole == 'ClientUser'
          )
            next()
          else next({ name: 'dashboard' })
        },
      },
      {
        path: '/client/all-job-surveys',
        name: 'client-all-job-surveys',
        component: () => import('@/views/folder-manager/All-JOB-Surveys.vue'),
        meta: { requiresAuth: true },
        props: true,
        beforeEnter: (to, from, next) => {
          if (
            store.getters.currentUserRole == 'SuperAdmin' ||
            store.getters.currentUserRole == 'LicensedDistributor' ||
            store.getters.currentUserRole == 'LDAssociate' ||
            store.getters.currentUserRole == 'PowerUser' ||
            store.getters.currentUserRole == 'ClientUser'
          )
            next()
          else next({ name: 'dashboard' })
        },
      },
      {
        path: '/client/job-survey-folder',
        name: 'client-job-survey-folder',
        component: () => import('@/views/folder-manager/Job-Survey-Folder.vue'),
        meta: { requiresAuth: true },
        props: true,
        beforeEnter: (to, from, next) => {
          if (
            store.getters.currentUserRole == 'SuperAdmin' ||
            store.getters.currentUserRole == 'LicensedDistributor' ||
            store.getters.currentUserRole == 'LDAssociate' ||
            store.getters.currentUserRole == 'PowerUser' ||
            store.getters.currentUserRole == 'ClientUser'
          )
            next()
          else next({ name: 'dashboard' })
        },
      },

      {
        path: '/client/surveys',
        name: 'client-surveys',
        props: true,
        component: () =>
          import('@/views/folder-manager/Client-Survey-Folder.vue'),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          if (
            store.getters.currentUserRole == 'SuperAdmin' ||
            store.getters.currentUserRole == 'LicensedDistributor' ||
            store.getters.currentUserRole == 'LDAssociate' ||
            store.getters.currentUserRole == 'PowerUser'
          )
            next()
          else next({ name: 'dashboard' })
        },
      },

      //end: Clients

      //start: Personal Folder
      {
        path: '/personal/survey-folder',
        name: 'personal/survey-folder',
        component: () => import('@/views/folder-manager/Personal-Folder.vue'),
        meta: { requiresAuth: true },
        props: true,
        beforeEnter: (to, from, next) => {
          if (
            store.getters.currentUserRole == 'SuperAdmin' ||
            store.getters.currentUserRole == 'LicensedDistributor' ||
            store.getters.currentUserRole == 'LDAssociate'
          )
            next()
          else next({ name: 'dashboard' })
        },
      },
      {
        path: '/personal/job-survey-folder',
        name: 'personal/job-survey-folder',
        component: () =>
          import('@/views/folder-manager/Personal-Job-Folder.vue'),
        meta: { requiresAuth: true },
        props: true,
        beforeEnter: (to, from, next) => {
          if (
            store.getters.currentUserRole == 'SuperAdmin' ||
            store.getters.currentUserRole == 'LicensedDistributor' ||
            store.getters.currentUserRole == 'LDAssociate'
          )
            next()
          else next({ name: 'dashboard' })
        },
      },
      //end: PersonalFolder

      //begin: SuperAdmin Licensed Distributor
      {
        path: '/super-admin/licenseddistributorlisting',
        name: 'sa-licensed-distributor-listing',
        component: () =>
          import(
            '@/views/super-admin/licensed-distributors/LicensedDistributorListing.vue'
          ),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          if (store.getters.currentUserRole !== 'SuperAdmin')
            next({ name: 'dashboard' })
          else next()
        },
      },
      {
        path: '/super-admin/licensed-distributor/:accountid',
        name: 'sa-licensed-distributor',
        component: () =>
          import(
            '@/views/super-admin/licensed-distributors/Licensed-Distributor.vue'
          ),
        meta: { requiresAuth: true },
        props: true,
        children: [
          {
            path: '/super-admin/licensed-distributors/ldclients/:accountid',
            name: 'sa-licensed-distributor-clients',
            component: () =>
              import(
                '@/views/super-admin/licensed-distributors/LD-Clients.vue'
              ),
            meta: { requiresAuth: true },
            props: true,
          },
          {
            path: '/super-admin/licensed-distributors/ldassociates/:accountid',
            name: 'sa-licensed-distributor-associates',
            component: () =>
              import(
                '@/views/super-admin/licensed-distributors/LD-Associates.vue'
              ),
            meta: { requiresAuth: true },
            props: true,
          },
        ],
      },
      //end: SuperAdmin Licensed Distributor

      //begin: Licensed Distributor
      {
        path: '/licensed-distributor/clients',
        name: 'licensed-distributor-clients',
        component: () =>
          import('@/views/licensed-distributor/clients/index.vue'),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          if (store.getters.currentUserRole !== 'LicensedDistributor')
            next({ name: 'dashboard' })
          else next()
        },
      },
      {
        path: '/licensed-distributor/LD-AssociateListing',
        name: 'licensed-distributor-associate-listing',
        component: () =>
          import('@/views/licensed-distributor/LD-AssociateListing.vue'),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          if (store.getters.currentUserRole !== 'LicensedDistributor')
            next({ name: 'dashboard' })
          else next()
        },
      },
      {
        path: '/licensed-distributor/licensed-distributor-associate/:accountid',
        name: 'licensed-distributor-associate',
        component: () =>
          import(
            '@/views/licensed-distributor-associate/Licensed-Distributor-Associate.vue'
          ),
        meta: { requiresAuth: true },
        props: true,
        children: [
          {
            path: '/ld-associate/clients/:accountid',
            name: 'licensed-distributor-associate-clients',
            component: () =>
              import('@/views/licensed-distributor/LD-Associate-Clients.vue'),
            meta: { requiresAuth: true },
            props: true,
          },
        ],
      },

      //end: Licensed Distributor

      //start: LD Associate
      {
        path: '/licensed-distributor-associate/clients',
        name: 'ld-associate-clients',
        component: () =>
          import('@/views/licensed-distributor-associate/clients/index.vue'),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          if (store.getters.currentUserRole !== 'LDAssociate')
            next({ name: 'dashboard' })
          else next()
        },
      },
      //end: LD Associate

      //start: Client
      {
        path: '/client/client-poweruser-list',
        name: 'client-poweruser-list',
        component: () => import('@/views/client/Client-Power-User-List.vue'),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          if (
            store.getters.currentUserRole == 'PowerUser' ||
            store.getters.currentUserRole == 'ClientUser'
          )
            next()
          else next({ name: 'dashboard' })
        },
      },
      {
        path: '/client/client-clientuser-list',
        name: 'client-clientuser-list',
        component: () => import('@/views/client/Client-Client-User-List.vue'),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          if (
            store.getters.currentUserRole == 'PowerUser' ||
            store.getters.currentUserRole == 'ClientUser'
          )
            next()
          else next({ name: 'dashboard' })
        },
      },
      {
        path: '/client/client-location-list',
        name: 'client-location-list',
        component: () => import('@/views/client/Client-Location-List.vue'),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          if (
            store.getters.currentUserRole == 'PowerUser' ||
            store.getters.currentUserRole == 'ClientUser'
          )
            next()
          else next({ name: 'dashboard' })
        },
      },
      {
        path: '/client/employees',
        name: 'client-employees',
        component: () => import('@/views/client/Employees.vue'),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          if (
            store.getters.currentUserRole == 'PowerUser' ||
            store.getters.currentUserRole == 'ClientUser'
          )
            next()
          else next({ name: 'dashboard' })
        },
      },
      {
        path: '/client/pendinghires',
        name: 'client-pending-hires',
        component: () => import('@/views/client/Pending-Hires.vue'),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          if (
            store.getters.currentUserRole == 'PowerUser' ||
            store.getters.currentUserRole == 'ClientUser'
          )
            next()
          else next({ name: 'dashboard' })
        },
      },
      {
        path: '/client/formeremployees',
        name: 'client-former-employees',
        component: () => import('@/views/client/Former-Employees.vue'),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          if (
            store.getters.currentUserRole == 'PowerUser' ||
            store.getters.currentUserRole == 'ClientUser'
          )
            next()
          else next({ name: 'dashboard' })
        },
      },
      {
        path: '/client/nothired',
        name: 'client-not-hired',
        component: () => import('@/views/client/Not-Hired.vue'),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          if (
            store.getters.currentUserRole == 'PowerUser' ||
            store.getters.currentUserRole == 'ClientUser'
          )
            next()
          else next({ name: 'dashboard' })
        },
      },
      {
        path: '/contact-content-expert',
        name: 'contact-content-expert',
        component: () => import('@/views/client/Contact-Content-Expert.vue'),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          if (
            store.getters.currentUserRole == 'PowerUser' ||
            store.getters.currentUserRole == 'ClientUser'
          )
            next()
          else next({ name: 'dashboard' })
        },
      },

      //end: Client

      //begin: Behavioral Survey
      {
        path: '/survey/enterbehavioralsurvey',
        name: 'incline-behavioral-survey-manual',
        component: () => import('@/views/survey/Enter-Behavioral-Survey.vue'),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          if (store.getters.currentUserRole === 'SuperAdmin') next()
          else next({ name: 'dashboard' })
        },
      },
      {
        path: '/survey/ld-enterbehavioralsurvey',
        name: 'ld-behavioral-survey-manual',
        component: () =>
          import('@/views/licensed-distributor/LD-Enter-Behavioral-Survey.vue'),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          if (store.getters.currentUserRole === 'LicensedDistributor') next()
          else next({ name: 'dashboard' })
        },
      },
      {
        path: '/survey/lda-enterbehavioralsurvey',
        name: 'lda-behavioral-survey-manual',
        component: () =>
          import(
            '@/views/licensed-distributor-associate/LDA-Enter-Behavioral-Survey.vue'
          ),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          if (store.getters.currentUserRole === 'LDAssociate') next()
          else next({ name: 'dashboard' })
        },
      },
      {
        path: '/survey/client-enterbehavioralsurvey',
        name: 'client-behavioral-survey-manual',
        component: () =>
          import('@/views/client/Client-Enter-Behavioral-Survey.vue'),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          if (
            store.getters.currentUserRole === 'PowerUser' ||
            store.getters.currentUserRole === 'ClientUser'
          )
            next()
          else next({ name: 'dashboard' })
        },
      },
      {
        path: '/survey/emailsurvey/:surveytype',
        name: 'incline-email-survey',
        component: () => import('@/views/survey/Email-Survey.vue'),
        meta: { requiresAuth: true },
        props: true,
        beforeEnter: (to, from, next) => {
          if (
            store.getters.currentUserRole == 'SuperAdmin' ||
            store.getters.currentUserRole == 'LicensedDistributor' ||
            store.getters.currentUserRole == 'LDAssociate'
          )
            next()
          else next({ name: 'dashboard' })
        },
      },
      {
        path: '/survey/ld-emailsurvey/:surveytype',
        name: 'ld-email-survey',
        component: () =>
          import('@/views/licensed-distributor/LD-Email-Survey.vue'),
        meta: { requiresAuth: true },
        props: true,
        beforeEnter: (to, from, next) => {
          if (store.getters.currentUserRole == 'LicensedDistributor') next()
          else next({ name: 'dashboard' })
        },
      },
      {
        path: '/survey/lda-emailsurvey/:surveytype',
        name: 'lda-email-survey',
        component: () =>
          import('@/views/licensed-distributor-associate/LDA-Email-Survey.vue'),
        meta: { requiresAuth: true },
        props: true,
        beforeEnter: (to, from, next) => {
          if (store.getters.currentUserRole == 'LDAssociate') next()
          else next({ name: 'dashboard' })
        },
      },
      {
        path: '/survey/client-emailsurvey/:surveytype',
        name: 'client-email-survey',
        component: () => import('@/views/client/Client-Email-Survey.vue'),
        meta: { requiresAuth: true },
        props: true,
        beforeEnter: (to, from, next) => {
          if (
            store.getters.currentUserRole == 'PowerUser' ||
            store.getters.currentUserRole == 'ClientUser'
          )
            next()
          else next({ name: 'dashboard' })
        },
      },
      {
        path: '/survey/sent-surveys/:surveytype',
        name: 'sent-surveys',
        component: () => import('@/views/survey/Sent-Surveys.vue'),
        meta: { requiresAuth: true },
        props: true,
        beforeEnter: (to, from, next) => {
          if (
            store.getters.currentUserRole == 'SuperAdmin' ||
            store.getters.currentUserRole == 'LicensedDistributor' ||
            store.getters.currentUserRole == 'LDAssociate' ||
            store.getters.currentUserRole == 'PowerUser' ||
            store.getters.currentUserRole == 'ClientUser'
          )
            next()
          else next({ name: 'dashboard' })
        },
      },
      {
        path: '/survey/sent-job-surveys/:surveytype',
        name: 'sent-job-surveys',
        component: () => import('@/views/survey/Sent-Job-Surveys.vue'),
        meta: { requiresAuth: true },
        props: true,
        beforeEnter: (to, from, next) => {
          if (
            store.getters.currentUserRole == 'SuperAdmin' ||
            store.getters.currentUserRole == 'LicensedDistributor' ||
            store.getters.currentUserRole == 'LDAssociate' ||
            store.getters.currentUserRole == 'PowerUser' ||
            store.getters.currentUserRole == 'ClientUser'
          )
            next()
          else next({ name: 'dashboard' })
        },
      },
      //end: Behavioral Survey

      //begin: JOB Survey
      {
        path: '/survey/enterjobsurvey',
        name: 'incline-job-survey-manual',
        component: () => import('@/views/survey/Enter-JOB-Survey.vue'),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          if (store.getters.currentUserRole === 'SuperAdmin') next()
          else next({ name: 'dashboard' })
        },
      },
      {
        path: '/survey/ld-enterjobsurvey',
        name: 'ld-job-survey-manual',
        component: () =>
          import('@/views/licensed-distributor/LD-Enter-Job-Survey.vue'),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          if (store.getters.currentUserRole === 'LicensedDistributor') next()
          else next({ name: 'dashboard' })
        },
      },
      {
        path: '/survey/lda-enterjobsurvey',
        name: 'lda-job-survey-manual',
        component: () =>
          import(
            '@/views/licensed-distributor-associate/LDA-Enter-Job-Survey.vue'
          ),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          if (store.getters.currentUserRole === 'LDAssociate') next()
          else next({ name: 'dashboard' })
        },
      },
      {
        path: '/survey/client-enterjobsurvey',
        name: 'client-job-survey-manual',
        component: () => import('@/views/client/Client-Enter-Job-Survey.vue'),
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          if (
            store.getters.currentUserRole === 'PowerUser' ||
            store.getters.currentUserRole === 'ClientUser'
          )
            next()
          else next({ name: 'dashboard' })
        },
      },
      //end: JOB Survey
    ],
  },
  // Take Surveys
  {
    path: '/takesurvey',
    props: (route) => ({ query: route.query }),
    component: () => import('@/views/take-surveys/TakeSurvey.vue'),
  },
  {
    path: '/takejobsurvey',
    props: (route) => ({ query: route.query }),
    component: () => import('@/views/take-surveys/TakeJobSurvey.vue'),
  },
  {
    path: '/takesurveysuccess',
    component: () => import('@/views/take-surveys/TakeSurveySuccess.vue'),
  },
  // Login and Forgot Password
  {
    path: '/',
    component: () => import('@/views/auth/Auth.vue'),
    children: [
      {
        path: '/sign-in',
        name: 'sign-in',
        component: () => import('@/views/auth/SignIn.vue'),
      },
      {
        path: '/sign-up',
        name: 'sign-up',
        component: () => import('@/views/auth/SignUp.vue'),
      },
      {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('@/views/auth/ForgotPassword.vue'),
      },
      {
        path: '/forgot-password-confirmation',
        name: 'forgot-password-confirmation',
        component: () => import('@/views/auth/ForgotPasswordConfirmation.vue'),
      },
      {
        path: '/reset-your-password',
        name: 'reset-your-password',
        props: (route) => ({ query: route.query }),
        component: () => import('@/views/auth/ResetYourPassword.vue'),
      },
    ],
  },

  {
    // the 404 route, when none of the above matches
    path: '/404',
    name: '404',
    component: () => import('@/views/error/Error404.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404',
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    store.dispatch(Actions.VERIFY_AUTH).then(
      //Promise returns Success
      () => {
        //console.log("success");
        if (store.getters.isUserAuthenticated) {
          next()
          return
        }
        next('/sign-in')
      },
      //Promise returns Failure
      () => {
        next('/sign-in')
      }
    )
  } else {
    next()
  }

  /*store.dispatch(Actions.VERIFY_AUTH).then(
    (response) => {
      console.log("success");
    },
    (error) => {
      console.log("error");
    }
  );*/

  /*if (to.matched.some((record) => record.meta.requiresAuth)) {
    //if (!store.state.user) store.dispatch(Actions.VERIFY_AUTH);
    console.log("beforeEach " + store.getters.isUserAuthenticated);
    if (store.getters.isUserAuthenticated) {
      next();
      return;
    }
    next("/sign-in");
  } else {
    next();
  }*/
})

router.beforeEach(() => {
  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0)
  }, 100)
})

export default router
