import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";
import { FolderLineItemDto } from "@/core/types/FolderTypes";
import { FolderSurveyLineItemDto } from "@/core/types/SurveyTypes";

@Module
export default class FolderModule extends VuexModule {
  clientFolderList: Array<FolderLineItemDto> = [];
  folderContents: Array<FolderSurveyLineItemDto> = [];
  currentClientBehavioralFolderId = 0;
  folderSurveyLineItemDto = {} as FolderLineItemDto;

  get getClientsFolders(): FolderLineItemDto[] {
    return this.clientFolderList;
  }

  get getFolderContents(): FolderSurveyLineItemDto[] {
    return this.folderContents;
  }

  get currentClientsBehavioralFolderId() {
    return this.currentClientBehavioralFolderId;
  }

  get getSurveyById() {
    return (surveyid: number) => {
      const found: boolean = this.folderContents.some(
        (el) => el.surveyId === surveyid
      );
      if (found) {
        return this.folderContents.find(
          (phrase) => phrase.surveyId === surveyid
        );
      } else {
        return this.folderSurveyLineItemDto;
      }
    };
  }

  @Mutation
  [Mutations.SET_CLIENT_FOLDER_LIST](payload) {
    this.clientFolderList = payload.folderLineItems;
  }

  @Mutation
  [Mutations.SET_FOLDER_CONTENTS](payload) {
    this.folderContents = payload.folderSurveyLineItems;
  }

  @Mutation
  [Mutations.SET_CLIENT_BEHAVIORAL_FOLDER_ID](payload) {
    this.currentClientBehavioralFolderId = payload;
  }

  @Mutation
  [Mutations.REMOVE_SURVEY_FROM_FOLDERCONTENTS](payload) {
    const objIndex = this.folderContents.findIndex(
      (obj) => obj.surveyId === payload.surveyId
    );
    this.folderContents.splice(objIndex, 1);
  }

  @Mutation
  [Mutations.UPDATE_REGENERATED_SURVEY_PDF](payload) {
    const objIndex = this.folderContents.findIndex(
      (obj) => obj.surveyId === payload.surveyId
    );

    if (this.folderContents[objIndex].surveyType === 5) {
      this.folderContents[objIndex].behavioralNarrativePdf =
        payload.behavioralNarrativePdf;
      this.folderContents[objIndex].naturalVersusEnvironmentalPdf =
        payload.naturalVsEnvironmentalPdf;
      this.folderContents[objIndex].coachingGuidePdf = payload.coachingGuidePdf;
      this.folderContents[objIndex].profileName = payload.behavioralProfileName;
      this.folderContents[objIndex].graphFilename =
        payload.naturalSelfGridImage;
    } else if (this.folderContents[objIndex].surveyType === 15) {
      this.folderContents[objIndex].jobResultsPdf = payload.jobResultsPdf;
      this.folderContents[objIndex].profileName = payload.jobProfileName;
      this.folderContents[objIndex].graphFilename = payload.jobGridImage;
    }
  }

  @Mutation
  [Mutations.PURGE_FOLDERS]() {
    this.clientFolderList = [] as Array<FolderLineItemDto>;
    this.folderContents = [] as Array<FolderSurveyLineItemDto>;
    this.currentClientBehavioralFolderId = 0;
  }

  @Mutation
  [Mutations.PURGE_FOLDER_CONTENTS]() {
    this.folderContents = [] as Array<FolderSurveyLineItemDto>;
  }

  @Action
  [Actions.GET_CLIENT_FOLDER_LIST](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("FolderMgmt/Client", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_CLIENT_FOLDER_LIST, data);
            resolve();
          } else {
            this.context.commit(Mutations.SET_ERROR, data);
            reject();
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_CLIENT_FOLDER_CONTENTS](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("FolderMgmt/Folder", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_FOLDER_CONTENTS, data);
            resolve();
          } else {
            this.context.commit(Mutations.SET_ERROR, data);
            reject();
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_BEHAVIORAL_PERSONAL_FOLDER_CONTENTS](payload) {
    ApiService.setHeader();
    ApiService.get("FolderMgmt/BehavioralPersonalFolder/" + payload)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_FOLDER_CONTENTS, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.GET_JOB_PERSONAL_FOLDER_CONTENTS](payload) {
    ApiService.setHeader();
    ApiService.get("FolderMgmt/JobPersonalFolder/" + payload)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_FOLDER_CONTENTS, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.PUT_REGENERATE_SURVEY_PDF](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.put("SurveyMgmt/RegenerateSurveyPdf", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.UPDATE_REGENERATED_SURVEY_PDF, data);
            resolve();
          } else {
            this.context.commit(Mutations.SET_ERROR, data);
            reject();
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          reject();
        });
    });
  }
}
