import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import {
  LicensedDistListItemDto,
  LicensedDistAssociateListItemDto,
  LicensedDistributor,
  ClientContentExpertResponseDto,
} from "../../core/types/LicensedDistributorTypes";
import ApiService from "@/core/services/ApiService";

@Module
export default class LicensedDistributorModule extends VuexModule {
  licensedDistributorList: Array<LicensedDistListItemDto> = [];
  licensedDistributorAssociateList: Array<LicensedDistAssociateListItemDto> =
    [];
  licensedDistributorDto = {} as LicensedDistributor;
  licensedDistributorId = 0;
  ldAssociatesLicensedDistributorId = 0;
  ldAssociatesLicensedDistAspNetUserId = "";
  contentExpert = {} as ClientContentExpertResponseDto;

  get getLicensedDistributorList(): LicensedDistListItemDto[] {
    return this.licensedDistributorList;
  }

  get getLicensedDistributorAssociateList(): LicensedDistAssociateListItemDto[] {
    return this.licensedDistributorAssociateList;
  }

  get getLicensedDistributorInfo() {
    return (distAspNetUserId: string) => {
      return this.licensedDistributorList.find(
        (dist) => dist.distAspNetUserId === distAspNetUserId
      );
    };
  }

  get getLicensedDistributorAssociateInfo() {
    return (associateAspNetUserId: string) => {
      return this.licensedDistributorAssociateList.find(
        (emp) => emp.associateAspNetUserId === associateAspNetUserId
      );
    };
  }

  get getLicensedDistId() {
    return this.licensedDistributorId;
  }

  get getLDAssociatesLicensedDistAspNetUserId() {
    return this.ldAssociatesLicensedDistAspNetUserId;
  }

  get getLDAssociatesLicensedDistributorId() {
    return this.ldAssociatesLicensedDistributorId;
  }

  get getClientContentExpert() {
    return this.contentExpert;
  }

  @Mutation
  [Mutations.SET_LICENSED_DISTRIBUTORS](payload) {
    this.licensedDistributorList = payload;
  }

  @Mutation
  [Mutations.ADD_A_LICENSED_DISTRIBUTOR](payload) {
    this.licensedDistributorList.unshift(payload);
  }

  @Mutation
  [Mutations.SET_LICENSED_DISTRIBUTOR_ID](payload) {
    this.licensedDistributorId = payload.licensedDistributorId;
  }

  @Mutation
  [Mutations.SET_CONTENT_EXPERT](payload) {
    this.contentExpert = payload;
  }

  @Mutation
  [Mutations.SET_LICENSED_DISTRIBUTOR_ASSOCIATES](payload) {
    this.licensedDistributorAssociateList = payload;
  }

  @Mutation
  [Mutations.ADD_A_LICENSED_DISTRIBUTOR_ASSOCIATE](payload) {
    this.licensedDistributorAssociateList.unshift(payload);
    //this.context.rootState.licensedDistAssociateUsers.unshift(payload);
  }

  @Mutation
  [Mutations.REMOVE_LICENSED_DISTRIBUTOR](payload) {
    const objIndex = this.licensedDistributorList.findIndex(
      (obj) => obj.licensedDistributorId === payload.licensedDistributorId
    );

    this.licensedDistributorList.slice(objIndex, 1);
  }

  @Mutation
  [Mutations.REMOVE_LICENSED_DISTRIBUTOR_ASSOCIATE](payload) {
    const objIndex = this.licensedDistributorAssociateList.findIndex(
      (obj) => obj.licensedDistributorId === payload.licensedDistributorId
    );
    this.licensedDistributorAssociateList.slice(objIndex, 1);
  }

  @Mutation
  [Mutations.SET_LDASSOCIATES_LICENSED_DIST_ASPNETUSERID](payload) {
    this.ldAssociatesLicensedDistAspNetUserId = payload;
  }

  @Mutation
  [Mutations.SET_LDASSOCIATES_LICENSED_DISTRIBUTOR_ID](payload) {
    this.ldAssociatesLicensedDistributorId =
      payload.ldAssociateLicensedDistributorId;
  }

  @Mutation
  [Mutations.PURGE_LICENSED_DISTRIBUTOR]() {
    this.licensedDistributorList = [] as Array<LicensedDistListItemDto>;
    this.licensedDistributorAssociateList =
      [] as Array<LicensedDistAssociateListItemDto>;
    this.licensedDistributorDto = {} as LicensedDistributor;
    this.licensedDistributorId = 0;
    this.ldAssociatesLicensedDistributorId = 0;
    this.ldAssociatesLicensedDistAspNetUserId = "";
  }

  @Action
  [Actions.GET_LICENSED_DISTRIBUTORS]() {
    ApiService.setHeader();
    ApiService.get("LicensedDistributorMgmt/LicensedDistributors")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_LICENSED_DISTRIBUTORS, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.GET_LICENSED_DISTRIBUTOR_LISTITEM](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get(
        "LicensedDistributorMgmt/GetLicensedDistributorListItem/" + payload
      )
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.ADD_A_LICENSED_DISTRIBUTOR, data);
            resolve(data);
          } else {
            this.context.commit(Mutations.SET_ERROR, data);
            reject();
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_LICENSED_DISTRIBUTOR_CLIENTS](payload) {
    ApiService.setHeader();
    ApiService.get("LicensedDistributorMgmt/Clients/" + payload)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_CLIENTS, data);
      })
      .catch(() => {
        this.context.commit(Mutations.SET_CLIENTS, [
          {
            name: "ERROR: Could not find a LD with DistAspNetUserId",
            numOfEmployees: 0,
            primaryContactName: "ERROR",
            primaryContactEmail: "ERROR",
            active: false,
          },
        ]);
      });
  }

  @Action
  [Actions.GET_LICENSED_DISTRIBUTOR_ID](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get(
        "LicensedDistributorMgmt/GetLicensedDistributorId/" + payload
      )
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_LICENSED_DISTRIBUTOR_ID, data);
            resolve(data);
          } else {
            this.context.commit(Mutations.SET_ERROR, data);
            reject();
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_LICENSED_DIST_ASSOCIATE_CLIENTS](payload) {
    ApiService.setHeader();
    ApiService.get("LicensedDistAssociatesMgmt/Clients/" + payload)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_CLIENTS, data);
      })
      .catch(() => {
        this.context.commit(Mutations.SET_CLIENTS, [
          {
            name: "ERROR: Could not find a LD with AssociateAspNetUserId",
            numOfEmployees: 0,
            primaryContactName: "ERROR",
            primaryContactEmail: "ERROR",
            active: false,
          },
        ]);
      });
  }

  @Action
  [Actions.GET_CONTENT_EXPERT](payload) {
    ApiService.setHeader();
    ApiService.get("LicensedDistAssociatesMgmt/ContentExpert/" + payload)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_CONTENT_EXPERT, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.POST_LICENSED_DISTRIBUTOR_ASSOC_CLIENT](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("LicensedDistAssociatesMgmt/AddClient", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.ADD_A_CLIENT, data);
            resolve(data);
          } else {
            this.context.commit(Mutations.SET_ERROR, data);
            reject();
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.REMOVE_LICENSED_DISTRIBUTOR_ASSOC_CLIENT](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("LicensedDistAssociatesMgmt/RemoveClient", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.REMOVE_CLIENT,
              data.licensedDistClient
            );
            resolve(data);
          } else {
            this.context.commit(Mutations.SET_ERROR, data);
            reject();
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_LDASSOCIATES_LICENSED_DIST_ASPNETUSERID](payload) {
    ApiService.setHeader();
    ApiService.get(
      "LicensedDistAssociatesMgmt/LicensedDistAspNetUserId/" + payload
    )
      .then(({ data }) => {
        this.context.commit(
          Mutations.SET_LDASSOCIATES_LICENSED_DIST_ASPNETUSERID,
          data
        );
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.GET_LDASSOCIATES_LICENSED_DISTRIBUTOR_ID](payload) {
    ApiService.setHeader();
    ApiService.get("LicensedDistAssociatesMgmt/LicensedDistId/" + payload)
      .then(({ data }) => {
        this.context.commit(
          Mutations.SET_LDASSOCIATES_LICENSED_DISTRIBUTOR_ID,
          data
        );
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.GET_LICENSED_DISTRIBUTOR_ASSOCIATES](payload) {
    ApiService.setHeader();
    ApiService.get("LicensedDistributorMgmt/Associates/" + payload)
      .then(({ data }) => {
        this.context.commit(
          Mutations.SET_LICENSED_DISTRIBUTOR_ASSOCIATES,
          data.licenseDistAssociateListItems
        );
        this.context.commit(
          Mutations.SET_LICENSED_DIST_ASSOCIATE_USERS,
          data.licensedDistAssociateUsers
        );
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.CREATE_LICENSED_DIST_ASSOCIATE](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("LicensedDistAssociatesMgmt/CreateAssociate", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.ADD_A_LICENSED_DISTRIBUTOR_ASSOCIATE,
              data
            );
            resolve(data);
          } else {
            this.context.commit(Mutations.SET_ERROR, data);
            reject();
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_LICENSED_DISTRIBUTOR](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.delete("LicensedDistributorMgmt/" + payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.REMOVE_LICENSED_DISTRIBUTOR, data);
            resolve();
          } else {
            this.context.commit(Mutations.SET_ERROR, data);
            reject();
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_LICENSED_DIST_ASSOCIATE](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.delete("LicensedDistributorMgmt/" + payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.REMOVE_LICENSED_DISTRIBUTOR_ASSOCIATE,
              data
            );
            resolve();
          } else {
            this.context.commit(Mutations.SET_ERROR, data);
            reject();
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          reject();
        });
    });
  }
}
