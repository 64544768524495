import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";
import { Respondent } from "@/core/types/RespondentTypes";

@Module
export default class RespondentModule extends VuexModule {
  respondentList: Array<Respondent> = [];
  newEmailRespondentListItem = {} as Respondent;

  get getRespondents(): Respondent[] {
    return this.respondentList;
  }

  get getNewEmailRespondent() {
    return this.newEmailRespondentListItem;
  }

  get getRespondent() {
    return (email: string) => {
      return this.respondentList.find(
        (respondent) => respondent.email === email
      );
    };
  }

  @Mutation
  [Mutations.SET_RESPONDENT_LIST](payload) {
    this.respondentList = payload.respondents;
  }

  @Mutation
  [Mutations.SET_NEW_EMAIL_RESPONDENT_LIST_ITEM](payload) {
    this.newEmailRespondentListItem = payload;
  }

  @Mutation
  [Mutations.ADD_NEW_RESPONDENT_TO_LIST](payload) {
    if (payload.userExisted) {
      const objIndex = this.respondentList.findIndex(
        (obj) => obj.aspNetUserId === payload.respondentListItemDto.aspNetUserId
      );
      this.respondentList.splice(objIndex, 1);
    }
    this.respondentList.unshift(payload.respondentListItemDto);
  }

  @Mutation
  [Mutations.PURGE_RESPONDENTS]() {
    this.respondentList = [] as Array<Respondent>;
  }

  @Action
  [Actions.POST_LOOKUP_RESPONDENTS](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("RespondentMgmt/RespondentLookup", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_RESPONDENT_LIST, data);
            resolve();
          } else {
            this.context.commit(Mutations.SET_ERROR, data);
            reject();
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_RESPONDENT_LIST](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("RespondentMgmt/List", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_RESPONDENT_LIST, data);
            resolve();
          } else {
            this.context.commit(Mutations.SET_ERROR, data);
            reject();
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_RESPONDENT_LIST_ITEM](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("RespondentMgmt/RespondentListItem", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_NEW_EMAIL_RESPONDENT_LIST_ITEM,
              data
            );
            resolve();
          } else {
            this.context.commit(Mutations.SET_ERROR, data.response);
            reject();
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_NEW_RESPONDENT](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("RespondentMgmt/Create", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.ADD_NEW_RESPONDENT_TO_LIST, data);
            resolve();
          } else {
            this.context.commit(Mutations.SET_ERROR, data);
            reject();
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          reject();
        });
    });
  }
}
