import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule, Mutation } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";
import { ExportToExcelResponseDto } from "@/core/types/ExcelTypes";

@Module
export default class ExcelModule extends VuexModule {
  exportToExcelResponseDto = {
    excelFilename: "na",
  } as ExportToExcelResponseDto;

  get getExcelFile(): ExportToExcelResponseDto {
    return this.exportToExcelResponseDto;
  }

  @Mutation
  [Mutations.SET_BEHAVIORAL_SURVEYS_TO_EXCEL](payload) {
    this.exportToExcelResponseDto = payload.excelFilename;
  }

  @Mutation
  [Mutations.PURGE_EXCEL_FILE]() {
    this.exportToExcelResponseDto = {
      excelFilename: "na",
    } as ExportToExcelResponseDto;
  }

  @Action
  [Actions.EXPORT_BEHAVIORAL_SURVEYS_TO_EXCEL](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("ExcelCreator/ExportBehavioralSurveys", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_BEHAVIORAL_SURVEYS_TO_EXCEL,
              data
            );
            resolve();
          } else {
            this.context.commit(Mutations.SET_ERROR, data);
            reject();
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.EXPORT_JOB_MATCH_TO_BEHAVIORAL_SURVEYS_TO_EXCEL](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("ExcelCreator/ExportJOBMatchToBehavioralSurveys", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_BEHAVIORAL_SURVEYS_TO_EXCEL,
              data
            );
            resolve();
          } else {
            this.context.commit(Mutations.SET_ERROR, data);
            reject();
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.EXPORT_SURVEY_VALIDITY_STUDY_TO_EXCEL](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("ExcelCreator/ExportSurveyValidityStudy", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_BEHAVIORAL_SURVEYS_TO_EXCEL,
              data
            );
            resolve();
          } else {
            this.context.commit(Mutations.SET_ERROR, data);
            reject();
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          reject();
        });
    });
  }
}
