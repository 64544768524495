import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import UserModule from "@/store/modules/UserModule";
import ClientModule from "@/store/modules/ClientModule";
import LicensedDistributorModule from "@/store/modules/LicensedDistributorModule";
import BehavioralWordsModule from "@/store/modules/BehavioralWordsModule";
import PrincipalTraitModule from "@/store/modules/PrincipalTraitModule";
import JobPhrasesModule from "@/store/modules/JobPhrasesModule";
import FormDataModule from "@/store/modules/FormDataModule";
import InclineEnumModule from "@/store/modules/InclineEnumModule";
import SurveyModule from "@/store/modules/SurveyModule";
import LookupModule from "@/store/modules/LookupModule";
import FolderModule from "@/store/modules/FolderModule";
import RespondentModule from "@/store/modules/RespondentModule";
import EmailModule from "@/store/modules/EmailModule";
import ExcelModule from "@/store/modules/ExcelModule";
import AnalysisModule from "@/store/modules/AnalysisModule";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    UserModule,
    ClientModule,
    LicensedDistributorModule,
    BehavioralWordsModule,
    PrincipalTraitModule,
    JobPhrasesModule,
    FormDataModule,
    InclineEnumModule,
    SurveyModule,
    LookupModule,
    FolderModule,
    RespondentModule,
    EmailModule,
    ExcelModule,
    AnalysisModule,
  },
  state: {
    usStates: [
      { stateName: "Alaska", stateAbbrev: "AK" },
      { stateName: "Alabama", stateAbbrev: "AL" },
      { stateName: "Arkansas", stateAbbrev: "AR" },
      { stateName: "Arizona", stateAbbrev: "AZ" },
      { stateName: "California", stateAbbrev: "CA" },
      { stateName: "Colorado", stateAbbrev: "CO" },
      { stateName: "Connecticut", stateAbbrev: "CT" },
      { stateName: "District of Columbia", stateAbbrev: "DC" },
      { stateName: "Delaware", stateAbbrev: "DE" },
      { stateName: "Florida", stateAbbrev: "FL" },
      { stateName: "Georgia", stateAbbrev: "GA" },
      { stateName: "Hawaii", stateAbbrev: "HI" },
      { stateName: "Iowa", stateAbbrev: "IA" },
      { stateName: "Idaho", stateAbbrev: "ID" },
      { stateName: "Illinois", stateAbbrev: "IL" },
      { stateName: "Indiana", stateAbbrev: "IN" },
      { stateName: "Kansas", stateAbbrev: "KS" },
      { stateName: "Kentucky", stateAbbrev: "KY" },
      { stateName: "Louisiana", stateAbbrev: "LA" },
      { stateName: "Massachusetts", stateAbbrev: "MA" },
      { stateName: "Maryland", stateAbbrev: "MD" },
      { stateName: "Maine", stateAbbrev: "ME" },
      { stateName: "Michigan", stateAbbrev: "MI" },
      { stateName: "Minnesota", stateAbbrev: "MN" },
      { stateName: "Missouri", stateAbbrev: "MO" },
      { stateName: "Mississippi", stateAbbrev: "MS" },
      { stateName: "Montana", stateAbbrev: "MT" },
      { stateName: "North Carolina", stateAbbrev: "NC" },
      { stateName: "North Dakota", stateAbbrev: "ND" },
      { stateName: "Nebraska", stateAbbrev: "NE" },
      { stateName: "Nevada", stateAbbrev: "NV" },
      { stateName: "New Hampshire", stateAbbrev: "NH" },
      { stateName: "New Jersey", stateAbbrev: "NJ" },
      { stateName: "New Mexico", stateAbbrev: "NM" },
      { stateName: "New York", stateAbbrev: "NY" },
      { stateName: "Ohio", stateAbbrev: "OH" },
      { stateName: "Oklahoma", stateAbbrev: "OK" },
      { stateName: "Oregon", stateAbbrev: "OR" },
      { stateName: "Pennsylvania", stateAbbrev: "PA" },
      { stateName: "Rhode Island", stateAbbrev: "RI" },
      { stateName: "South Carolina", stateAbbrev: "SC" },
      { stateName: "South Dakota", stateAbbrev: "SD" },
      { stateName: "Tennessee", stateAbbrev: "TN" },
      { stateName: "Texas", stateAbbrev: "TX" },
      { stateName: "Utah", stateAbbrev: "UT" },
      { stateName: "Virginia", stateAbbrev: "VA" },
      { stateName: "Vermont", stateAbbrev: "VT" },
      { stateName: "Washington", stateAbbrev: "WA" },
      { stateName: "Wisconsin", stateAbbrev: "WI" },
      { stateName: "West Virginia", stateAbbrev: "WV" },
      { stateName: "Wyoming", stateAbbrev: "WY" },
    ],
  },
});

export default store;
