import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";
import { EmailLookup } from "@/core/types/LookupTypes";

@Module
export default class LookupModule extends VuexModule {
  emailLookupResponse = {} as EmailLookup;

  get getEmailLookupResponse(): EmailLookup {
    return this.emailLookupResponse;
  }

  @Mutation
  [Mutations.SET_EMAIL_LOOKUP_RESPONSE](payload) {
    this.emailLookupResponse = payload;
  }

  @Mutation
  [Mutations.PURGE_LOOKUPS]() {
    this.emailLookupResponse = {} as EmailLookup;
  }

  @Action
  [Actions.LOOKUP_EMAIL](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("Lookup/Email", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_EMAIL_LOOKUP_RESPONSE, data);
            resolve();
          } else {
            this.context.commit(Mutations.SET_ERROR, data);
            reject();
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          reject();
        });
    });
  }
}
